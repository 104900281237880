import { ChangeDetectorRef, Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { ReplyTypeComponent } from '../reply-type/reply-type.component';
import { ReplyAutomatizationConfComponent } from '../reply-automatization-conf/reply-automatization-conf.component';
import { UtilService } from '../../../shared/util.service';
import { SmartRepliesService } from '../smart-replies.service';
import { Location } from '@angular/common';
import { catchError, finalize, tap } from 'rxjs/operators';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { CustomersState } from 'app/states/customers/customers.state';
import { Select } from '@ngxs/store';
import { AlertService } from 'app/core-lib/services/alerts/alert.service';


@Component({
  selector: 'mh-smart-replies-conf',
  templateUrl: './smart-replies-conf.component.html',
  styleUrls: ['./smart-replies-conf.component.scss'],
})
export class SmartRepliesConfComponent {
  @Input() accept_terms;
  readonly typeConfigSlug: string = 'smart_reply_type_config_slug';
  readonly productConfigSlug: string = 'smart_reply_product_config_slug';
  @ViewChild(ReplyTypeComponent) replyTypeComponent!: ReplyTypeComponent;
  @ViewChild(ReplyAutomatizationConfComponent) replyAutomatizationConfComponent!: ReplyAutomatizationConfComponent;

  customer_id = null;
  customizeConf;
  loading: boolean;
  extensions: any;
  tones: any;
  subscription = new Subscription();
  @Select(CustomersState.currentCustomer) $currentCustomer: Observable<any>;

  constructor(
    private smartRepliesService: SmartRepliesService,
    private location: Location,
    private alertService: AlertService,
  ) {}
  userAcceptance: boolean;

  readonly productsList = [
    { id: 1, key: 'FollowUp' },
    { id: 2, key: 'OnSite' },
    { id: 3, key: 'Online' },
  ];

  ngOnInit(): void {    
    this.subscription.add(
      this.$currentCustomer.subscribe((customer: any) => {
        if (customer) {
          this.customer_id = customer.id;
          this.initializeData();
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { accept_terms: currentValue } = changes;
    if( currentValue.currentValue === undefined ) return
    this.replyAutomatizationConfComponent?.setResponsibility(currentValue.currentValue);
  }

  async initializeData() {
    this.loading = true;
    forkJoin([
      this.smartRepliesService.getCustomizeConf(this.customer_id),
      this.smartRepliesService.getTones(),
      this.smartRepliesService.getExtensions(),
    ])
      .pipe(
        tap(([customizeConfResponse, tones, extensions]) => {
          this.customizeConf = customizeConfResponse.data;          
          this.tones = (tones as any).data;
          this.extensions = (extensions as any).data;
        }),
        catchError((err) => {
          console.error('Error:', err);
          return of([]);
        }),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe();
  }

  sendConf() {
    const payloadCustomize = {
      customer_id: this.customer_id,
      customize_config: this.customize_config,
      products_config: this.products_config,
    };

    const payloadResponsibility = {
      accepted: this.responsibility,
    };

    this.loading = true;
    forkJoin([
      this.smartRepliesService.postCustomizeConf(this.customer_id, payloadCustomize),
      this.smartRepliesService.postResponsibility(this.customer_id, payloadResponsibility),
    ])
      .pipe(
        tap(() => {
          this.customizeConf = payloadCustomize;          
          this.replyAutomatizationConfComponent.setResponsibility(this.responsibility);
          this.showNotification('models.user.data_saved_correctly');
        }),
        catchError((err) => {
          console.error('Error:', err);
          this.showNotification('waiting.messages.wait_fail', 'error');
          return of([]);
        }),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe();
  }

  showNotification(messageKey: string, type = 'success') {
    this.alertService.handleAlert(messageKey, 5000, 'bottom', 'end', `snackbar-panel-${type}-user-config`);
  }

  goToBack() {
    this.location.back();
  }

  get customize_config() {
    return this.replyTypeComponent.replyForm.getRawValue().customize_config;
  }

  get products_config() {
    return this.replyAutomatizationConfComponent.productsConfigForm.getRawValue().products_config;
  }

  get responsibility() {
    return this.replyAutomatizationConfComponent?.userWarningControl?.value || false;
  }
}
