import { Component, ViewChild } from '@angular/core';
import { SmartRepliesService } from '../smart-replies.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select } from '@ngxs/store';
import { CustomersState } from 'app/states/customers/customers.state';
import { Observable, Subscription } from 'rxjs';
import { UtilService } from 'app/shared/util.service';
import { SmartRepliesConfComponent } from '../smart-replies-conf/smart-replies-conf.component';
@Component({
  selector: 'mh-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss'],
})
export class ConfigComponent {
  unlimiteResponses = false;
  loading = true;
  form: FormGroup;
  subscription = new Subscription();
  config = null;
  customerId = null;
  isSA: boolean = false;
  readonly answerLimitSlug: string = 'smart_reply_limit_config_slug';
  @Select(CustomersState.currentCustomer) $currentCustomer: Observable<any>;
  accept_terms: any;
  @ViewChild(SmartRepliesConfComponent) smartRepliesConfComponent!: SmartRepliesConfComponent;
  constructor(
    private smartRepliesService: SmartRepliesService,
    private fb: FormBuilder,
    private utilService: UtilService,
  ) {
    this.subscription.add(
      this.$currentCustomer.subscribe((customer: any) => {
        if (customer) {
          this.customerId = customer.id;
          this.init();
        }
      }),
    );
  }

  init() {
    this.smartRepliesService.getConfig(this.customerId).subscribe((response: any) => {
      this.accept_terms = response.data.accept_terms;
      const config = {
        usage_limit: response.data.month_usage_limit,
        unlimited: response.data.unlimited,
      };
      this.config = config;
      this.setForm(config);
      this.loading = false;
    });

    this.isSA = this.utilService.currentUser.isSuperAdmin();
  }
  
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setForm(config) {
    this.form = this.fb.group({
      usage_limit: [{ value: config.usage_limit, disabled: config.unlimited }, [Validators.min(0)]],
      flag_unlimited: [config.unlimited],
    });
  }

  saveConfig() {
    const body = {
      ...this.form.getRawValue(),
    };

    this.loading = true;
    this.smartRepliesService.saveConfig(this.customerId, body).subscribe(
      () => {
        this.loading = false;
      },
      () => (this.loading = false),
    );
    this.smartRepliesConfComponent.sendConf();
  }

  goToBack() {
    this.smartRepliesConfComponent.goToBack();
  }

  numbersOnly(event) {
    const regExp = /^([0-9])$/;
    const result = regExp.test(event.key);
    return result;
  }

  unlimiteResponsesChange() {
    this.unlimitedFlagCtrl.setValue(!this.unlimitedFlagCtrl.value);
    if (this.unlimitedFlagCtrl.value) {
      this.usageCtrl.disable();
    } else {
      this.usageCtrl.enable();
      this.usageCtrl.setValue(this.config.usage_limit);
    }
  }

  get usageCtrl() {
    return this.form.get('usage_limit');
  }

  get unlimitedFlagCtrl() {
    return this.form.get('flag_unlimited');
  }

  get responsibility() {
    return this.smartRepliesConfComponent?.responsibility || false;
  }
}
