import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SmartRepliesUtilsService {
  readonly urlSRConfig = '/new-fs/smart-replies/configuracion/';
  constructor(private router: Router) {}

  handleConfigClicked() {
    const fullUrl = this.router.serializeUrl(this.router.createUrlTree([this.urlSRConfig]));
    window.open(fullUrl, '_blank');
  }

}
