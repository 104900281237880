import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SurveyAnswerService } from 'app/shared/surveys/survey-answer.service';
import { DialogRef } from '../modal/dialog-ref';
import { DIALOG_DATA } from '../modal/dialog-tokens';
import { CoreLibModalComponent } from '../modal/modal.component';
import { ReasonsList } from 'app/shared/model';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { DefaultLanguageState } from 'app/states/default-language/default-language.state';
import { AlertService } from 'app/core-lib/services/alerts/alert.service';
import { UtilService } from 'app/shared/util.service';

@Component({
  selector: 'mh-core-lib-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class CoreLibConfirmationModalComponent extends CoreLibModalComponent implements OnInit {
  @Select(DefaultLanguageState.languageCode) language$: Observable<string>;

  survey: any;
  deleteReasonList: ReasonsList;
  deleteForm: FormGroup;

  constructor(
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
    private surveyAnswerService: SurveyAnswerService,
    private fb: FormBuilder,
    private store: Store,
    private alertService: AlertService,
    private utilService: UtilService,
  ) {
    super(dialogRef, data);
  }

  ngOnInit(): void {
    this.setSurvey();
    if (this.data.deleteReasonList) {
      const langId =
        this.store.selectSnapshot(DefaultLanguageState.language)?.languageId || this.utilService.getLanguageId();
      this.deleteReasonList = this.data.deleteReasonList.filter((reason) => reason.language_id === langId);
    }
    this.deleteForm = this.fb.group({
      reason: [null, Validators.required],
      reasonComment: [null],
    });
  }

  setSurvey() {
    this.survey = this.data.survey;
  }

  displayFn(option: any): string {
    return option ? option.reason : '';
  }

  handleReasonChange(event: any) {
    this.deleteForm.patchValue({
      reason: event,
    });
  }

  handleDelete() {
    const body = {
      reason_comment: this.deleteForm.value.reasonComment,
      reason_id: this.deleteForm.value.reason.id,
      translates: this.deleteForm.value.reason.translates,
    };

    this.surveyAnswerService.deleteAnswerSurvey(this.survey.answered_survey.id, body).subscribe(
      (response: any) => {
        if (response && response.delete) {
          const surveyCopy = { ...this.survey };
          surveyCopy.deleted = true;
          this.alertService.handleAlert(
            'core-lib.dialog.confirmation.delete_survey_notify',
            5000,
            'bottom',
            'end',
            'snackbar-panel-success-user-config',
          );
          this.close(surveyCopy);
        }
      },
      () => {
        this.alertService.handleAlert('user_management.actions.error', 5000, 'bottom', 'end', 'snackbar-panel-error');
      },
    );
  }

  handleCancel() {
    this.close();
  }
}
