import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'mh-core-lib-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class CoreLibButtonComponent {
  @Input()
  isPrimary = false;

  @Input()
  isDefault = false;

  @Input()
  isSecondary = false;

  @Input()
  isAction = false;

  @Input()
  isFeedback = false;

  @Input()
  isDisabled = false;

  @Input()
  hasImgIcon = false;

  @Input()
  icon: string | null = null;

  @Input()
  text = '';

  @Input()
  imgIconUrl = '';

  @Input()
  width = 160;

  @Input()
  height = 42;

  @Input()
  customClass = '';

  @Input()
  textClass = '';

  @Input()
  customIconClass = '';

  @Input()
  emoji = '';

  @Input()
  customEmojiClass = '';

  @Input()
  isDownloading = false;

  @Input()
  loaderClass = '';

  @Output()
  clicked = new EventEmitter();

  handleClick() {
    if (!this.isDisabled) {
      this.clicked.emit();
    }
  }
}
