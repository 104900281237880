
    <section class="tw-pt-6 tw-px-4">
        <p class="tw-font-bold">{{ TRANSLATE_PREFIX + 'auto-reply-title' | translate }}</p>
        <p>{{TRANSLATE_PREFIX + 'auto-reply-subtitle' | translate}}</p>
    </section>

    <section class="tw-flex tw-flex-col md:tw-flex-row tw-py-8">
        <div class="tw-relative tw-overflow-x-auto tw-px-3 md:tw-w-2/3">
            <form [formGroup]="productsConfigForm" class="tw-h-full" >
                <table class="tw-w-full tw-text-sm tw-text-left tw-min-w-full tw-h-full">
                    <thead class="tw-text-xs tw-text-gray-400 tw-uppercase tw-font-bold">
                        <tr class="tw-bg-white tw-border-b">
                            <th scope="col" class="tw-px-3 tw-py-3 tw-w-1/6">{{TRANSLATE_PREFIX + 'module' | translate}}</th>
                            <th scope="col" class="tw-px-3 tw-py-3 tw-w-1/6 tw-min-w-[155px]">{{TRANSLATE_PREFIX + 'state' | translate}}</th>
                            <th scope="col" class="tw-px-3 tw-py-3 tw-w-1/4  tw-min-w-[300px]">{{TRANSLATE_PREFIX + 'nps-type-ans' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody formArrayName="products_config">
                        <tr *ngFor="let product of products.controls; let i = index" [formGroupName]="i" class="tw-bg-white tw-border-b">
                            <th scope="row" class="tw-px-3 tw-py-4 tw-font-medium tw-text-gray-900 tw-whitespace-nowrap">{{ productsList[i].key  }}</th>
                            <td class="tw-py-4 tw-px-3">
                                <mh-core-lib-toggle
                                formControlName="flag_active"
                                [label]="product.get('flag_active')?.value ? ((TRANSLATE_PREFIX +'active') | translate) : ((TRANSLATE_PREFIX +'deactive') | translate)" 
                                labelPosition="horizontal"
                                ></mh-core-lib-toggle>
                            </td>
                            <td class="tw-py-4 tw-px-3 tw-min-w-[350px]">
                                <section class="tw-flex tw-justify-between tw-my-5" >
                                    <mh-core-lib-toggle formControlName="flag_promoter" [label]="((TRANSLATE_PREFIX +'promoter') | translate)" viewType="checkbox" ></mh-core-lib-toggle>
                                    <mh-core-lib-toggle formControlName="flag_passive" [label]="((TRANSLATE_PREFIX +'passive') | translate)" viewType="checkbox" ></mh-core-lib-toggle>
                                    <mh-core-lib-toggle formControlName="flag_detractor" [label]="((TRANSLATE_PREFIX +'detractor') | translate)" viewType="checkbox" ></mh-core-lib-toggle>
                                </section>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>

        <div class="md:tw-w-1/3 tw-border tw-border-gray-500 tw-p-4 tw-rounded tw-flex tw-flex-col tw-justify-between tw-m-2">
            <div class="tw-flex tw-items-center">
                <div class="tw-text-yellow-500 tw-text-xl">
                    ⚠
                </div>
                <p class="tw-pl-2 tw-font-bold">
                    {{TRANSLATE_PREFIX + 'important' | translate}}
                </p>
            </div>

            <p class="tw-space-y-1">
                <br>
                {{TRANSLATE_PREFIX + 'warn-1' | translate}}
                <br> <br>
                {{ TRANSLATE_PREFIX + 'warn-2' | translate:{hotel_name: CustomerName} }}
                <br><br>
            </p>
            <mh-core-lib-toggle labelCheckboxClass="!tw-italic" [label]="(TRANSLATE_PREFIX +'user_warn') | translate" viewType="checkbox" [formControl]="userWarningControl"  ></mh-core-lib-toggle>
        </div>
    </section>
