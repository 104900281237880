import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UtilService } from '../../../shared/util.service';
import { HotelDto, Hotel } from '../../../shared/model/index';
import { HotelService } from '../../hotel/hotel.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormEditor } from '../../../shared/utils/form-editor';
import { CustomerService } from '../../../shared/customer.service';
import { plainToClass } from 'class-transformer';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'mh-customer-hotel-form',
  templateUrl: './hotel-form.component.html',
  styleUrls: ['./hotel-form.component.scss'],
  providers: [HotelService, FormEditor, CustomerService],
})
export class CustomerHotelFormComponent {
  @Input() hotel: any;
  @Input() viewType = 'edit';

  @Output() updateFinish: EventEmitter<boolean> = new EventEmitter();
  @Output() creationFinish: EventEmitter<boolean> = new EventEmitter();

  waiting = true;
  waitingElements = false;
  waitingSave = false;

  hotelDto: HotelDto;
  hotelForm: FormGroup;
  hotel_base = {};
  formReady = {};
  isReady = false;
  listOptions;
  changeCity = false;
  canEdit = false;
  isSuperAdmin = false;
  apiKey = '';
  showInputApiKey = false;
  defaultDisabled = true;
  isCompetitor = false;

  defaultDisabledFields = [
    'tramo',
    'licence_type',
    'city',
    'country',
    'rooms_quantity',
    'phone',
    'web_site',
    'cs_executives',
    'commercial_executive',
    'monthly_billing_usd',
    'integration_type',
    'address1',
    'subscription_as_string',
  ];

  hotel_information = [
    'name',
    'nick_id',
    'phone',
    'address1',
    'contact1',
    'web_site',
    'rooms_quantity',
    'country',
    'city',
    'currency',
    'language',
  ];
  hotel_properties = [
    'property_type',
    'default_checkin',
    'default_checkout',
    'seasonal',
    'flag_show_translations',
    'integration_type',
    'commercial_executive',
    'cs_executives',
    'tramo',
    'licence_type',
    'subscription_as_string',
    'monthly_billing_usd',
    'stage',
    'hubspot_id',
    'id_xero',
    'pipedrive_id',
  ];
  hotel_products = [
    'client',
    'followup',
    'online',
    'onsite',
    'collect',
    'pre_stay',
    'online_competitor',
    'app',
    'cases',
    'semantic',
    'smart_replies',
    'whatsapp',
  ];
  hotel_popups = [
    'paid',
    'payment_warning',
    'free_test_notice',
    'free_test_notice_end',
    'free_test_notice_followup',
    'free_test_notice_followup_end',
    'free_test_notice_online',
    'free_test_notice_online_end',
    'free_test_notice_onsite',
    'free_test_notice_onsite_end',
    'flag_integracion_caida',
  ];

  constructor(
    private utilService: UtilService,
    private hotelService: HotelService,
    private fb: FormBuilder,
    private customerService: CustomerService,
  ) {}

  ngOnInit() {
    this.canEdit = ['full-admin', 'customer-success', 'payments'].includes(this.utilService.currentUser.role);
    this.isSuperAdmin = this.utilService.getCurrentUser().isSuperAdmin();
  }

  getColClass(key: string): number {
    const keysInOneRow = ['stage'];
    const defaultCol = 4;
    if ( keysInOneRow.includes(key) ) {
      return 12;
    } else {
      return defaultCol;
    }
  }

  ngOnChanges() {
    if (this.viewType === 'edit') {
      this.getProfile();
    } else {
      this.hotelDto = this.hotel;
      this.initCustomer();
    }
  }

  reset() {
    this.formReady = {
      parent: false,
      images: false,
      chain: false,
    };
  }

  getProfile() {
    this.waiting = true;
    this.hotelService.getHotel(this.hotel.id).subscribe((response) => {
      console.log(response);
      this.hotelDto = plainToClass<HotelDto, Object>(HotelDto, response);
      this.hotelDto.checkHotelDefaults();
      this.isCompetitor = this.hotelDto.online_competitor;
      this.initCustomer();
    });
  }

  initCustomer() {
    this.waiting = true;

    forkJoin([
      this.hotelService.getBase(),
      this.customerService.getListUTC(),
      this.customerService.getListChain(),
      this.hotelService.getCities(this.hotelDto.country.code2),
    ]).subscribe(([base, UTC, chain, cities]: any[]) => {
      this.listOptions = {
        hours: this.utilService.listHours(),
        utc: UTC,
        currency: this.hotelService.availableCurrencies(base.currencies),
        countries: base.countries,
        tramo: ['Oro', 'Plata', 'Bronce', 'ICP', 'NO ICP'],
        licence_type: [
          'Cliente',
          'Test',
          'Cliente Retencion',
          'Cliente Downgrade',
          'Cliente Fugado',
          'Prueba Pagada',
          'Prueba Gratis',
          'Prueba Perdida',
          'Freemium',
          'Competidor',
          'Canje',
          'Alianza',
        ],
        stage: base.stages,
        language: this.utilService.languages,
        property_type: base.property_types.filter((p: { description: string }) => p.description !== 'Private'),
        cities: this.utilService.sortArray(cities, 'name'),
        chain: [{ id: null, name: 'none' }, ...this.utilService.sortArray(chain, 'name')],
        commercial_executive: this.getBaseCommercialExecutives(base.commercial_executives),
        cs_executives: base.cs_executives.map((ex) => ({
          name: `${ex.first_name} ${ex.last_name}`,
          id: ex.id,
        })),
      };

      this.createForm();
    });
  }

  createForm() {
    this.reset();
    this.readyFormPart('parent');

    this.hotelForm = this.fb.group({
      id: this.hotelDto.id,
      name: [this.hotelDto.name, [Validators.required, Validators.maxLength(100), Validators.minLength(5)]],
      nick_id: [this.hotelDto.nick_id, [Validators.required, Validators.maxLength(100), Validators.minLength(5)]],
      phone: [{ value: this.hotelDto.phone, disabled: !this.isCompetitor }],
      city: [
        { value: this.hotelDto.city.deleted ? null : this.hotelDto.city, disabled: !this.isCompetitor },
        [Validators.required, Validators.minLength(1)],
      ],
      country: [
        { value: this.hotelDto.country, disabled: !this.isCompetitor },
        [Validators.required, Validators.minLength(1)],
      ],
      address1: [{ value: this.hotelDto.address1, disabled: !this.isCompetitor }, [Validators.maxLength(100)]],
      default_checkin: [this.hotelDto.default_checkin, [Validators.required]],
      default_checkout: [this.hotelDto.default_checkout, [Validators.required]],
      contact1: this.hotelDto.contact1,
      web_site: [{ value: this.hotelDto.web_site, disabled: !this.isCompetitor }],
      currency: this.hotelDto.currency,
      monthly_billing_usd: [
        { value: this.hotelDto.monthly_billing_usd, disabled: !this.isCompetitor },
        [Validators.pattern(/^\d*(?:[.,]\d{1,2})?$/)],
      ],
      property_type: this.hotelDto.property_type,
      utc: this.hotelDto.utc,
      automatic_utc: this.hotelDto.automatic_utc,
      language: this.hotelDto.language,
      pipedrive_id: [this.hotelDto.pipedrive_id],
      group_id: this.hotelDto.group_id,
      stage:  this.listOptions.stage.find( option => option.id === this.hotelDto.stage),
      commercial_executive: [{ value: this.hotelDto.commercial_executive, disabled: this.isReadOnlyConfig }],
      cs_executives: [{ value: this.hotelDto.cs_executive, disabled: this.isReadOnlyConfig }],
      rooms_quantity: [{ value: this.hotelDto.rooms_quantity, disabled: !this.isCompetitor }, [Validators.required]],
      licence_type: [{ value: this.hotelDto.licence_type, disabled: !this.isCompetitor }],
      subscription: [{ value: this.hotelDto.subscription, disabled: !this.isCompetitor }],
      subscription_as_string: [{ value: this.hotelDto.subscription_as_string, disabled: !this.isCompetitor }],
      tramo: [{ value: this.hotelDto.tramo, disabled: !this.isCompetitor }],
      seasonal: { value: this.hotelDto.seasonal, disabled: !this.canEdit },
      integration_type: { value: this.hotelDto.integration_type, disabled: !this.isCompetitor },
      images: this.fb.array([]),

      integration_siteminder: this.fb.group({
        flag_active: { value: this.hotelDto.integration_siteminder.flag_active, disabled: !this.canEdit },
      }),
      ...this.hotel_products.reduce(
        (acum: any, key: string) => ({
          ...acum,
          [key]: { value: this.hotelDto[key], disabled: !this.canEdit },
        }),
        {},
      ),

      deleted: { value: this.hotelDto.deleted, disabled: !this.canEdit },

      paid: [{ value: this.hotelDto.paid, disabled: !this.canEdit }, [Validators.required]],
      payment_warning: [{ value: this.hotelDto.payment_warning, disabled: !this.canEdit }, [Validators.required]],
      free_test_notice: [{ value: this.hotelDto.free_test_notice, disabled: !this.canEdit }, [Validators.required]],
      free_test_notice_end: [
        { value: this.hotelDto.free_test_notice_end, disabled: !this.canEdit },
        [Validators.required],
      ],
      free_test_notice_followup: [
        { value: this.hotelDto.free_test_notice_followup, disabled: !this.canEdit },
        [Validators.required],
      ],
      free_test_notice_followup_end: [
        { value: this.hotelDto.free_test_notice_followup_end, disabled: !this.canEdit },
        [Validators.required],
      ],
      free_test_notice_online: [
        { value: this.hotelDto.free_test_notice_online, disabled: !this.canEdit },
        [Validators.required],
      ],
      free_test_notice_online_end: [
        { value: this.hotelDto.free_test_notice_online_end, disabled: !this.canEdit },
        [Validators.required],
      ],
      free_test_notice_onsite: [
        { value: this.hotelDto.free_test_notice_onsite, disabled: !this.canEdit },
        [Validators.required],
      ],
      free_test_notice_onsite_end: [
        { value: this.hotelDto.free_test_notice_onsite_end, disabled: !this.canEdit },
        [Validators.required],
      ],
      flag_integracion_caida: [
        { value: this.hotelDto.flag_integracion_caida, disabled: !this.canEdit },
        [Validators.required],
      ],

      chain_property: this.fb.group({
        chain: this.hotelDto.chain_property.chain,
        internal_code: { value: this.hotelDto.chain_property.internal_code, disabled: !this.canEdit },
        internal_name: { value: this.hotelDto.chain_property.internal_name, disabled: !this.canEdit },
        business_line: ['Hotel'],
        brand: { value: this.hotelDto.chain_property.brand, disabled: !this.canEdit },
        region: { value: this.hotelDto.chain_property.region, disabled: !this.canEdit },
      }),
      flag_show_translations: { value: this.hotelDto.flag_show_translations, disabled: !this.canEdit },
    });

    this.hotelForm.get('online').valueChanges.subscribe((value) => {
      if (value) this.hotelForm.get('smart_replies').setValue(true);
    });

    this.waiting = false;
  }

  getBaseCommercialExecutives(commExecutives) {
    return commExecutives.map((comExe) => {
      return {
        name: `${comExe.first_name} ${comExe.last_name}`,
        id: comExe.id,
      };
    });
  }

  setProductsToActive(key: string) {
    const products = ['followup', 'online', 'onsite', 'pre_stay'];
    if (key === 'online_competitor') {
      this.isCompetitor = !this.isCompetitor;
      return;
    }
    if (products.find((p) => p === key)) {
      const prodValue = this.hotelForm.controls[key].value;
      if (prodValue && key !== 'prestay') this.hotelForm.controls.semantic.setValue(true);
      if (prodValue) this.hotelForm.controls.cases.setValue(true);
    }
  }

  readyFormPart(name) {
    this.formReady[name] = true;
    let isReady = true;
    for (const key of Object.keys(this.formReady)) {
      const status = this.formReady[key];
      if (!status) isReady = false;
    }
    this.isReady = isReady;
  }

  changeCityList() {
    this.changeCity = true;
    this.setUpdateElements(this.changeCity);
    this.hotelService.getCities(this.hotelForm.controls.country.value.code2).subscribe((cities: any) => {
      this.listOptions.cities = this.utilService.sortArray(cities, 'name');
      this.hotelForm.controls.city.setValue(this.listOptions.cities[0], { emitEvent: true });
      this.changeCity = false;
      this.setUpdateElements(this.changeCity);
    });
  }

  setSelectlValue($event, key) {
    const selector = ['currency', 'country', 'city', 'language', 'property_type', 'stage'].includes(key) ? 'original' : 'value';
    this.hotelForm.controls[key].setValue($event[selector], { emitEvent: true });
    if (key === 'country') this.changeCityList();
  }

  setUpdateElements(state: boolean) {
    this.waitingElements = state;
  }

  updateCsExecutive() {
    const hotelDto = this.hotelForm.getRawValue();
    hotelDto.cs_executive = hotelDto.cs_executives;
    hotelDto.stage = hotelDto?.stage?.id;
    return hotelDto;
  }

  saveData() {
    this.waitingSave = true;
    const hotelDto = this.updateCsExecutive();
    const action = this.viewType === 'edit' ? 'updateHotel' : 'createHotel';

    this.hotelService[action](hotelDto).subscribe(
      (response) => {
        if (response && response !== 0) {
          hotelDto.id = response;
          this.customerService.findHotel(hotelDto.id).subscribe((hotel_api) => {
            const hotel = new Hotel().createFromApi(hotel_api);
            const accessIds = this.utilService.currentHotel.accessIds.slice();
            const flagsIds = this.utilService.currentHotel.flagsIds.slice();
            const integration_status = this.utilService.currentHotel.integration_status_dto;
            hotel.accessIds = accessIds;
            hotel.flagsIds = flagsIds;
            hotel.integration_status_dto = integration_status;
            this.waitingSave = false;
            if (hotelDto.client) {
              this.utilService.customerChanged(hotel);
            }
            if (this.viewType === 'edit') {
              this.updateFinish.emit(true);
            } else {
              this.utilService.sendAlert('success.login.something_went_wrong', 'success');
              this.waitingSave = false;
              this.creationFinish.emit(true);
              this.utilService.newHotelAdded();
            }
          });
        } else {
          this.alertError();
        }
      },
      () => {
        this.alertError();
      },
    );
  }

  alertError() {
    this.utilService.sendAlert('errors.login.something_went_wrong');
    this.waitingSave = false;
  }

  showApiKey() {
    this.showInputApiKey = true;
    this.customerService.getApiKey(this.hotel.id).subscribe((response: any) => {
      this.apiKey = response.access_key;
    });
  }

  goToDoc() {
    const urlDoc = 'https://documenter.getpostman.com/view/2432103/SWLfan47?version=latest';
    window.open(urlDoc, '_blank');
  }

  goToWebsite() {
    const url = `${this.hotelDto.web_site}`;
    const hasProtocol = url.split('://');
    const finalUrl = hasProtocol.length > 1 ? url : `http://${url}`;
    window.open(finalUrl, '_blank');
  }

  handlePipedriveButton() {
    const pipedriveId = this.hotelForm.controls.pipedrive_id.value;
    const url = `https://myhotel.pipedrive.com/organization/${pipedriveId}`;
    window.open(url, '_blank');
  }

  handleXeroButton() {
    const xeroId = this.hotelDto.id_xero;
    const url = `https://go.xero.com/Contacts/View/${xeroId}`;
    window.open(url, '_blank');
  }

  handleHubspotButton() {
    const husbpotId = this.hotelDto.hubspot_id;
    const url = `https://app.hubspot.com/contacts/23490592/record/0-2/${husbpotId}`;
    window.open(url, '_blank');
  }

  get isPipedriveDisabled() {
    const pipedriveId = this.hotelForm.controls.pipedrive_id.value;
    return !pipedriveId;
  }

  get isXeroDisabled() {
    const xeroId = this.hotelDto.id_xero;
    return !xeroId;
  }

  get ishHubspotDisabled() {
    const hubspotId = this.hotelDto.hubspot_id;
    return !hubspotId;
  }

  get hasWebsite() {
    const website = this.hotelDto.web_site;
    return !website;
  }

  get isReadOnlyConfig() {
    return this.viewType !== 'new' && this.hotelDto && !this.hotelDto.online_competitor;
  }
}
