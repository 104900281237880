<mh-alerts-wrapper>

  <section class="tw-flex tw-justify-between tw-items-center">
    <div>
      <mh-core-lib-datepicker
          [currentDates]="currentDates"
          [currentFilter]="currentFilterDate"
          (datepickerUpdated)="handleDatepickerUpdated($event)">
      </mh-core-lib-datepicker>
    </div>
    <div>
      <mh-filter-date
          [startDate]="startDate"
          [endDate]="endDate"
          [report]="fidelityPermisions.includes('interchain_collect_report') ? 'chainCollectSummary' : null "
          [reportActiveEmail]="true"
          [checkPermissions]="true"
          slug="corporative_collect_properties_report">
      </mh-filter-date>
    </div>
  </section>
  <ng-container *ngIf="!waiting">
    <mh-box-element [title]="('inter-chain.summary.title' | translate )" *ngIf="fidelityPermisions.includes('interchain_collect_result')">
      <widget-filter-chain-zone [productId]="productId" (chainChanged)="setChain($event)" [disabled]="!isReady"></widget-filter-chain-zone>
      <mh-survey-wrapper [productId]="productId" [chain]="chainFilter" [listenCustomerSubscribe]="false" #surveyWrapper>
        <mh-chain-collect-summary [currentDates]="currentDates" [chain]="chainFilter" [headerKeys]="headerTable"  (isReady)="dataIsReady($event)" ></mh-chain-collect-summary>
      </mh-survey-wrapper>
    </mh-box-element>
  </ng-container>

</mh-alerts-wrapper>
