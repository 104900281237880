<div class="tw-relative tw-w-[90vw] tw-h-screen md:tw-h-[75vh] tw-max-w-[1400px] tw-mt-2">
    <article class="tw-w-full tw-h-full tw-absolute tw-inset-0 tw-z-10 tw-flex tw-flex-col tw-p-4">
        <div class="tw-w-full tw-flex tw-items-start tw-justify-between">
            <section class="tw-w-3/4">
                <form [formGroup]="exampleForm" class="tw-flex tw-gap-4 tw-flex-wrap">
                    <select
                        class="tw-bg-gray-100 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg tw-w-[200px] tw-p-2.5"
                        formControlName="product_id" [disabled]="loading" >
                        <option *ngFor="let type of types" [value]="type.id">{{  TRANSLATE_PREFIX + type.description | translate }}</option>
                    </select>

                    <select
                        class="tw-bg-gray-100 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg tw-w-[200px] tw-p-2.5"
                        formControlName="tone_id" [disabled]="loading" >
                        <option *ngFor="let tone of tones" [value]="tone.id">{{  TRANSLATE_PREFIX + tone.description | translate }}</option>
                    </select>

                    <select
                        class="tw-bg-gray-100 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg tw-w-[200px] tw-p-2.5"
                        formControlName="extension_id" [disabled]="loading" >
                        <option *ngFor="let extension of extensions" [value]="extension.id">{{ TRANSLATE_PREFIX + extension.description | translate }}
                        </option>
                    </select>
                </form>
            </section>
            <section class="tw-flex tw-justify-end tw-w-1/4">
                <span class="hover:tw-cursor-pointer" (click)="handleClose()">
                    <i class="far fa-times tw-text-3xl tw-text-gray-400"></i>
                </span>
            </section>
        </div>

        <mh-core-lib-loader-wrapper [loading]="loading" className="tw-text-[50px] tw-p-4  !tw-h-[300px]" type="spinner" >
            <section
                class="tw-flex tw-flex-col tw-w-full tw-flex-grow tw-gap-3 tw-justify-start tw-overflow-auto tw-py-6 tw-m-3">
                <p class="tw-text-lg tw-font-semibold" *ngIf="product_idValue === 1" >NPS: 1</p>
                <p class="tw-text-lg" [ngClass]="{'tw-font-semibold': product_idValue === 3}" >{{ example_header }}</p>
                <p class="tw-text-sm tw-text-gray-600 tw-mt-2">{{ source_content }}</p>
                <div class="tw-border-t tw-border-gray-300 tw-mt-2"></div>
                <p class="tw-text-sm tw-text-gray-600 tw-mt-2">{{ example_body }}
                </p>
            </section>
        </mh-core-lib-loader-wrapper>
    </article>
</div>