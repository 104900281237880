import { Component, OnInit, Input } from '@angular/core';
import { FollowupPropertiesService } from '../../properties/followup-properties.service';
import { FilterDate } from 'app/shared/model/filters/filter-date.model';
import { UtilService } from 'app/shared/util.service';
import { calculateWeightedSum } from 'app/shared/helpers/arrays';
import { Subscription } from 'rxjs/internal/Subscription';
import { Hotel, Period } from 'app/shared/model';
import { MatTableDataSource } from '@angular/material/table';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CustomersState } from 'app/states/customers/customers.state';

export interface DataResponse {
  areas: DataAreaResponse[];
  all_areas_average_rating: Period;
  all_areas_total_answers: Period;
}

export interface DataAreaResponse {
  area_id: number;
  answers: Period;
  rating: Period;
}

@Component({
  selector: 'mh-chain-followup-areas',
  templateUrl: './chain-followup-areas.component.html',
  styleUrls: ['./chain-followup-areas.component.scss'],
  providers: [FollowupPropertiesService]
})
export class ChainFollowupAreasComponent implements OnInit {

  @Select(CustomersState.relativeCurrentChain) customersReady$: Observable<any[]>;

  @Input()
  productId = 1;

  @Input()
  chain: Hotel[] = [];

  @Input()
  currentDates = null;

  totals = {};
  tooltipHeaders = ['answers']

  waiting = true;
  data: DataResponse;

  firstCall = true;
  surveySubscribe: Subscription;
  customersStateSubscribe: Subscription;

  headerTable: any[] = [];
  dataTable: any[] = [];
  dataSource: any = new MatTableDataSource([]);

  constructor(
    private utilService: UtilService,
    private followupPropertiesService: FollowupPropertiesService,
    private store: Store
  ) {

    this.surveySubscribe = this.utilService.isCurrentSurveyChanged().subscribe(surveId => {
      if ( this.currentDates ) {
        this.init();
      }
    })

    this.customersStateSubscribe = this.customersReady$.subscribe(customers => {
      if ( this.currentDates ) {
        this.init();
      }
    });
  }

  ngOnDestroy(){
    this.surveySubscribe.unsubscribe();
    this.customersStateSubscribe.unsubscribe();
  }

  reset(){
    this.dataTable = [];
    this.dataSource = new MatTableDataSource(this.dataTable);
    this.headerTable = ['area','rating','answers'];
    this.totals = {};
  }

  ngOnInit() {
  }

  ngOnChanges({currentDates}) {
    if ( currentDates ) {
      this.init();
    }
  }

  init(){
    this.waiting = true;
    this.reset();
    const date      = new FilterDate(this.currentDates.startDate, this.currentDates.endDate);
    const survey    = this.utilService.getCurrentSurvey(this.chain.map(x=>parseInt(x.id)));
    const surveyIds = survey.ids;
    const version = survey.survey[0].version;
    const language  = this.utilService.getLanguageId();
    const customers = this.chain ?this.chain.map((c) => c.id).join(',') : this.store.selectSnapshot(CustomersState.relativeCurrentChain).map((c) => c.id).join(',');

    if ( customers.length ) {
      this.followupPropertiesService.getRankignAreas(date, customers,this.productId, language,surveyIds, version).subscribe((response:any)=>{
        this.data = response.average;
        this.createTable();
        this.firstCall = false;
        this.waiting = false;
      })
    }
  }


  createTable(){
    this.dataTable = [];

    this.dataTable = this.data.areas.map((area) => ({
      area: area.area_id,
      rating: area.rating,
      answers: area.answers,
    }));

    const {
      actual: actualAreas,
      previous: previousAreas,
      variation: variationAreas,
    } = this.data.all_areas_average_rating;
    const totalRating = new Period(actualAreas, previousAreas, variationAreas, null);
    const {
      actual: actualAnswers,
      previous: previousAnswers,
      variation: variationAnswers,
    } = this.data.all_areas_total_answers;
    const totalAnswers = new Period(actualAnswers, previousAnswers, variationAnswers, null);

    this.totals = {
      rating: totalRating,
      answers: totalAnswers,
    };

    this.dataSource = new MatTableDataSource(this.dataTable);
  }
}
