import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mh-dimensions-iro-widget',
  templateUrl: './dimensions-iro-widget.component.html',
  styleUrls: ['./dimensions-iro-widget.component.scss']
})
export class DimensionsIroWidgetComponent {
  @Input() dimensions = null;
  @Input() loading: boolean;
  @Input() error: boolean;
  @Output() retryFetch = new EventEmitter();

  get year() {
    return new Date().getFullYear();
  }
}
