import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'mh-chart-widget',
  templateUrl: './chart-widget.component.html',
  styleUrls: ['./chart-widget.component.scss'],
})
export class ChartWidgetComponent implements OnInit {
  @Input() lineChars;
  @Input() isHotel = true;
  @Input() loading: boolean;
  @Input() error: boolean;
  @Output() retryFetch = new EventEmitter();
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0.5,
      },
    },
    legend: {
      display: true,
      position: 'top',
      align: 'start',
      labels: {
        usePointStyle: true,
        boxWidth: 8,
      },
      onHover: function (e) {
        e.target.style.cursor = 'pointer';
      },
    },
    hover: {
      onHover: function (e) {
        e.target.style.cursor = 'default';
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            padding: 10,
            min: 0,
            max: 100,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            autoSkip: true,
          },
        },
      ],
    },
  };

  ngOnInit() {
    // yAxis scale when is no hotel is 0-5
    this.lineChartOptions.scales.yAxes[0].ticks.max = this.isHotel ? 100 : 5;
    (Chart as any).Legend.prototype.afterFit = function () {
      this.height += 20;
    };
  }

  get title() {
    return this.isHotel ? 'iro.trends.title' : 'iro.trends.title-score';
  }
}
