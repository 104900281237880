import { Component, Inject } from '@angular/core';
import { DialogRef } from '../modal/dialog-ref';
import { DIALOG_DATA } from '../modal/dialog-tokens';
import { CoreLibModalComponent } from '../modal/modal.component';

@Component({
  selector: 'mh-core-lib-otas-integration-status-modal',
  templateUrl: './otas-integration-status-modal.component.html',
  styleUrls: ['./otas-integration-status-modal.component.scss'],
})
export class CoreLibOtasIntegrationStatusModalComponent extends CoreLibModalComponent {
  statusColorMap = {
    green: '#2bbb39',
    yellow: '#ffc107',
  };
  readonly GOOGLE_ID = 30;
  readonly FACEBOOK_ID = 31;
  readonly TRIPADVISOR_ID = 1;
  readonly BOOKING_ID = 2;
  readonly EXPEDIA_ID = 34;
  readonly OTAS = [this.GOOGLE_ID, this.FACEBOOK_ID, this.BOOKING_ID, this.TRIPADVISOR_ID, this.EXPEDIA_ID];
  readonly customerOtaMap = new Map<number, Map<number, any>>();
  constructor(protected dialogRef: DialogRef, @Inject(DIALOG_DATA) public data: any) {
    super(dialogRef, data);
  }

  ngOnInit(): void {
    this.otasErrors?.forEach(ota => {
      ota?.status?.forEach(status => {
        if ( this.OTAS.includes(status?.ota_id) ) {
          if (!this.customerOtaMap.has(ota?.customer.id)) {
            this.customerOtaMap.set(ota?.customer?.id, new Map<number, any>());
          }
          this.customerOtaMap.get(ota?.customer?.id)?.set(status?.ota_id, status);
        }
      });
    });
  }

  handleConnect(customer, otaId) {
    this.dialogRef.close({
      customer,
      otaId,
    });
  }

  handleClose() {
    return this.dialogRef.close();
  }

  isOtaPresent(otaId, status) {
    return !!status.find((otaStatus) => otaStatus.ota_id === otaId);
  }

  existError(otaId, status) {
    const otaStatus = status.find((otaStatus) => otaStatus.ota_id === otaId);
    return otaStatus.status_color === 'red';
  }

  get currentLanguage() {
    return this.data.currentLanguage;
  }

  get otasErrors() {
    return this.data.otasErrors;
  }

  get isMenuContextCorporative() {
    return this.data.menuContext === 'corporative';
  }
}
