import { Component, EventEmitter, Input, Output, OnInit, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UtilService } from 'app/shared/util.service';

@Component({
  selector: 'mh-reply-automatization-conf',
  templateUrl: './reply-automatization-conf.component.html',
  styleUrls: ['./reply-automatization-conf.component.scss'],
})
export class ReplyAutomatizationConfComponent {
  readonly TRANSLATE_PREFIX = 'smart_replies.config.';

  userAcceptance: boolean = false;
  @Input() responseData;
  @Input() productsList;
  @Input() accept_terms;
  userWarningControl = new FormControl(false);

  productsConfigForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private utilService: UtilService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.productsConfigForm = this.fb.group({
      products_config: this.fb.array(this.createProductsConfig()),
    });

    this.products.controls.forEach((productGroup: FormGroup) => {
      const flagActiveControl = productGroup.get('flag_active');
      const dependentControls = ['flag_promoter'].map((name) =>
        productGroup.get(name),
      );

      flagActiveControl?.valueChanges.subscribe((isActive: boolean) => {
        if (isActive) {
          dependentControls.forEach((control) => control?.enable());
        } else {
          dependentControls.forEach((control) => control?.disable());
        }
      });

      if (!flagActiveControl?.value) {
        dependentControls.forEach((control) => control?.disable());
      }
    });

    this.productsConfigForm.patchValue(this.responseData);

    this.userWarningControl.setValue(this.accept_terms);
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges({ responseData: { currentValue } }: SimpleChanges): void {
    this.productsConfigForm?.patchValue(currentValue);
  }

  private createProductsConfig() {
    return this.productsList.map((product) =>
      this.fb.group({
        product_id: [product.id],
        flag_active: [false],
        flag_promoter: [false],
        flag_passive: [{ value: false, disabled: true }], 
        flag_detractor: [{ value: false, disabled: true }],
      })
    );
  }

  get products(): FormArray {
    return this.productsConfigForm.get('products_config') as FormArray;
  }

  get CustomerName(): string {
    return this.utilService.getCurrentCustomer().name;
  }

  setResponsibility(accepted) {
    this.userWarningControl.setValue(accepted);
    this.changeDetectorRef.detectChanges();
  }
}
