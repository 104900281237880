<section class="tw-flex tw-justify-between tw-items-center">
  <div>
    <mh-core-lib-datepicker
      [currentDates]="currentDates"
      [currentFilter]="currentFilterDate"
      (datepickerUpdated)="handleDatepickerUpdated($event)">
    </mh-core-lib-datepicker>
  </div>
  <div>
    <mh-filter-date
        [startDate]="startDate"
        [endDate]="endDate"
        [type]="'trends'"
        [persistDate]="false"
        [report]="fidelityPermisions.includes('interchain_followup_trends_report') ? report : null "
        [reportActiveEmail]="true"
        [checkPermissions]="true"
        [slug]="reportSlug"></mh-filter-date>
  </div>
</section>
<ng-container>
  <mh-box-element [title]="('inter-chain.summary.title' | translate )" *ngIf="fidelityPermisions.includes('interchain_followup_trends_result')">
    <div class="filter-widget" [class.float]="surveyWrapper.showFilter">
      <widget-filter-chain-zone [productId]="productId" (chainChanged)="setChain($event)" [disabled]="!isReady"></widget-filter-chain-zone>
    </div>
    <div class="content-wrapper" >
      <mh-survey-wrapper [productId]="productId" [chain]="chainFilter" [listenCustomerSubscribe]="false" #surveyWrapper>
        <mh-inter-chain-trends [currentDates]="currentDates" [showFilters]="showFilters" [chain]="chainFilter" [showAreas]="showAreas" (isReady)="dataIsReady($event)" [productId]="productId" ></mh-inter-chain-trends>
      </mh-survey-wrapper>
    </div>
  </mh-box-element>
</ng-container>

