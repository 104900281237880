import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { CustomerIroData, IROTrend, MetricsResponse, ResponseData, ScoreData } from './interchain-online-summary.model';

@Injectable({
  providedIn: 'root',
})
export class OnlineSummaryService {
  constructor(private http: HttpClient) {}

  getInterChainIROMetricByCustomerIds(
    customersIds: string,
    startDate: string,
    endDate: string,
  ): Observable<ResponseData> {
    const url = `${environment.onlinePath}interchains/iro/metrics`;
    const params = new HttpParams()
      .set('customers_ids', customersIds)
      .set('start_date', startDate)
      .set('end_date', endDate);
    return this.http.get<ResponseData>(url, { params });
  }

  getInterChainRankingIROByCustomerIds(
    customersIds: string,
    startDate: string,
    endDate: string,
  ): Observable<CustomerIroData[]> {
    const url = `${environment.onlinePath}interchains/iro/ranking`;
    const params = new HttpParams()
      .set('customers_ids', customersIds)
      .set('start_date', startDate)
      .set('end_date', endDate);
    return this.http.get<CustomerIroData[]>(url, { params });
  }

  getGlobalIRORanking(startDate: string, endDate: string): Observable<ScoreData> {
    const url = `${environment.onlinePath}benchmark/iro/ranking/averages`;
    const params = new HttpParams().set('start_date', startDate).set('end_date', endDate);
    return this.http.get<ScoreData>(url, { params });
  }

  getInterChainMainSummaryByCustomerIds(
    customersIds: string,
    startDate: string,
    endDate: string,
  ): Observable<MetricsResponse> {
    const url = `${environment.onlinePath}interchains/main/summary`;
    const params = new HttpParams()
      .set('customer_ids', customersIds)
      .set('start_date', startDate)
      .set('end_date', endDate);
    return this.http.get<MetricsResponse>(url, { params });
  }

  getInterChainIROTrendByCustomerIds(
    customersIds: string,
    startDate: string,
    endDate: string,
    segmentedBy: string,
  ): Observable<IROTrend> {
    const url = `${environment.onlinePath}interchains/iro/trend`;
    const params = new HttpParams()
      .set('customers_ids', customersIds)
      .set('start_date', startDate)
      .set('end_date', endDate)
      .set('segmented_by', segmentedBy);
    return this.http.get<IROTrend>(url, { params });
  }

  getInterChainSemanticIndexByCustomerIds(
    customersIds: string,
    productId: number,
    startDate: string,
    endDate: string,
    previousPeriod: boolean,
  ): Observable<ResponseData> {
    const url = `${environment.customerPath}customers/semantic/index`;
    const params = new HttpParams()
      .set('customers_ids', customersIds)
      .set('product_id', productId)
      .set('previous_period', previousPeriod.toString())
      .set('start_date', startDate)
      .set('end_date', endDate);
    return this.http.get<ResponseData>(url, { params });
  }
}
