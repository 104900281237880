<mh-core-lib-generic-box customClass="tw-min-h-[305px] tw-min-h-[305px] tw-h-[305px] tw-pt-[25px] tw-px-[25px] tw-pb-[20px]">
  <mh-core-lib-loader-wrapper [loading]="loading" [type]="'spinner'" className="tw-text-3xl">
    <article *ngIf="!error && dimensions else errorContainer" class="tw-grid tw-grid-rows-[60px_1fr] tw-gap-3">
      <h2 class="tw-font-bold tw-text-xl tw-text-main-text">{{'iro.dimensions.title' | translate}}</h2>
      <section class="tw-grid tw-grid-cols-[3fr_1fr_1fr]">
        <div class="tw-border-b tw-py-1.5"></div>
        <div class="tw-border-b tw-py-1.5 tw-text-center">{{'iro.dimensions.period' | translate}}</div>
        <div class="tw-border-b tw-py-1.5 tw-text-center">{{'iro.dimensions.accumulated' | translate: {year} }}</div>

        <div class="tw-border-b tw-py-1.5 tw-font-medium">{{'iro.dimensions.qualification' | translate}}</div>
        <div class="tw-border-b tw-py-1.5 tw-flex tw-justify-center tw-gap-1">
          <span class="tw-font-medium">{{dimensions.qualification.actual}}</span>
          <ng-container *ngTemplateOutlet="variation; context:{variation: dimensions.qualification.variation}"></ng-container>
        </div>
        <div class="tw-border-b tw-py-1.5 tw-flex tw-justify-center tw-gap-1">
          <span class="tw-font-medium">{{dimensions.qualification_annual.actual}}</span>
          <ng-container *ngTemplateOutlet="variation; context:{variation: dimensions.qualification_annual.variation}"></ng-container>
        </div>
        <div class="tw-border-b tw-py-1.5 tw-font-medium">{{'iro.dimensions.reviews' | translate}}</div>
        <div class="tw-border-b tw-py-1.5 tw-flex tw-justify-center tw-gap-1">
          <span class="tw-font-medium">{{dimensions.quantity.actual}}</span>
          <ng-container *ngTemplateOutlet="variation; context:{variation: dimensions.quantity.variation}"></ng-container>
        </div>
        <div class="tw-border-b tw-py-1.5 tw-flex tw-justify-center tw-gap-1">
          <span class="tw-font-medium">{{dimensions.quantity_annual.actual}}</span>
          <ng-container *ngTemplateOutlet="variation; context:{variation: dimensions.quantity_annual.variation}"></ng-container>
        </div>

        <div class="tw-border-b tw-py-1.5 tw-font-medium">{{'iro.dimensions.coverage' | translate}}</div>
        <div class="tw-border-b tw-py-1.5 tw-flex tw-justify-center tw-gap-1">
          <span class="tw-font-medium">{{dimensions.coverage | formatCoverage: 'actual'}}</span>
          <ng-container *ngTemplateOutlet="variation; context:{variation: dimensions.coverage.variation}"></ng-container>
        </div>
        <div class="tw-border-b tw-py-1.5 tw-flex tw-justify-center tw-gap-1">
          <span class="tw-font-medium">{{dimensions.coverage_annual | formatCoverage: 'actual'}}</span>
          <ng-container *ngTemplateOutlet="variation; context:{variation: dimensions.coverage_annual.variation}"></ng-container>
        </div>

        <div class="tw-border-b tw-py-1.5 tw-font-medium">{{'iro.dimensions.nps' | translate}}</div>
        <div class="tw-border-b tw-py-1.5 tw-flex tw-justify-center tw-gap-1">
          <span class="tw-font-medium">{{dimensions.nps.actual | formatBy: 'nps'}}</span>
          <ng-container *ngTemplateOutlet="variation; context:{variation: dimensions.nps.variation, nps: true}"></ng-container>
        </div>
        <div class="tw-border-b tw-py-1.5 tw-flex tw-justify-center tw-gap-1">
          <span class="tw-font-medium">{{dimensions.nps_annual.actual | formatBy: 'nps'}}</span>
          <ng-container *ngTemplateOutlet="variation; context:{variation: dimensions.nps_annual.variation, nps: true}"></ng-container>
        </div>


      </section>
    </article>
  </mh-core-lib-loader-wrapper>
</mh-core-lib-generic-box>

<ng-template #errorContainer>
  <article class="tw-flex tw-flex-col error-container">
    <h2 class="tw-font-bold tw-text-xl tw-text-main-text">{{'iro.dimensions.title' | translate}}</h2>
    <section class="tw-grid tw-grid-cols-2">
      <figure class="tw-place-self-center">
        <img class="tw-max-w-[250px]" src="https://mh-statics-dev-new.s3.sa-east-1.amazonaws.com/uploads/fidelity-suite/img/no+connection.png" alt="no connection">
      </figure>
      <article class="tw-grid tw-grid-rows-3 error-msg">
        <p class="tw-text-main-text tw-font-medium tw-row-start-2 tw-self-center tw-text-lg">
        {{'iro.commons.error' | translate}}
        </p>
        <button class="focus:tw-outline-0 tw-row-start-3 tw-text-main tw-font-medium tw-self-end tw-place-self-end tw-mt-1.5 tw-text-xl" (click)="retryFetch.emit()">{{'iro.commons.reload' | translate}}</button>
      </article>
    </section>
  </article>
</ng-template>

<ng-template #variation let-variation="variation" let-nps="nps">
  <span *ngIf="variation > 0" class="tw-text-promoters tw-text-xs tw-self-center tw-font-semibold">
    <ng-container *ngIf="nps">
      +{{variation}}
    </ng-container>
    <ng-container *ngIf="!nps">
      +{{variation | formatPercent:true:false:true}}
    </ng-container>
  </span>
  <span *ngIf="variation < 0" class="tw-text-detractors tw-text-xs tw-self-center tw-font-semibold">
    <ng-container *ngIf="nps">
      {{variation}}
    </ng-container>
    <ng-container *ngIf="!nps">
      {{variation | formatPercent:true:false:true}}
    </ng-container>
  </span>
  <span *ngIf="variation === 0" class="tw-text-main-text tw-text-xs tw-self-center tw-font-semibold">
    <ng-container *ngIf="nps">
      {{variation}}
    </ng-container>
    <ng-container *ngIf="!nps">
      {{variation | formatPercent:true:false:true}}
    </ng-container>
  </span>
</ng-template>
