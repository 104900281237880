import {Component, Input} from '@angular/core';
import {UtilService}    from "app/shared/util.service";
import { getAverageAreas } from "app/shared/helpers";
import { Hotel, FilterDate, Period } from 'app/shared/model';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs/internal/Subscription';
import { ChainExcutiveService } from './chain-executive.service';
import { forkJoin, Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { CustomersState } from 'app/states/customers/customers.state';

export class DataResponse{
  accumulated_brands: any[] = [];
  accumulated_previous_brands: any[] = [];
  brands: any [] = [];
  accumulated_regions: any[] = [];
  accumulated_previous_regions: any[] = [];
  regions: any [] = [];
}

@Component({
  selector: 'mh-chain-executive',
  templateUrl: './chain-executive.component.html',
  styleUrls: ['./chain-executive.component.scss'],
  providers: [ChainExcutiveService]
})
export class ChainExecutiveComponent{

  @Select(CustomersState.relativeCurrentChain) customersReady$: Observable<any[]>;

  @Input()
  metrics: string[] = [];

  @Input()
  chain: Hotel[] = [];

  @Input()
  productId = 1;

  @Input()
  currentDates = null;

  totals = {}

  waiting   = true;
  firstCall = true;

  surveySubscribe: Subscription;
  customerSubscribe: Subscription;
  customersStateSubscribe: Subscription;

  filters        = {};
  filterSelected = {};
  filtersTypes: any[]   = [];

  data: DataResponse;
  dataAverage;
  currentYear: number;
  previousYear: number;

  prefixTranslateFAQ = 'inter-chain.online.executive.faq';
  faqs = (new Array(1).fill(0));
  showWeightedAverage = true;


  //Table Vars
  dataSourceBrands: any  = new MatTableDataSource([]);
  dataSourceRegions: any = new MatTableDataSource([]);

  constructor(
    private utilService: UtilService,
    private chainExcutiveService: ChainExcutiveService,
    private store: Store
  ) {

      this.surveySubscribe = this.utilService.isCurrentSurveyChanged().subscribe(surveId => {
        if(this.productId!==3 && this.currentDates) this.init();
      })

      this.customerSubscribe = this.utilService.isCustomerChanged().subscribe(response=>{
        if(this.listenCustomerChange && this.currentDates){
          this.chain = this.utilService.getInnerChain(false,'online');
          this.init();
        }
      })
      this.customersStateSubscribe = this.customersReady$.subscribe(customers => {
        if ( this.currentDates ) {
          this.init();
        }
      });
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges({currentDates}) {
    if ( currentDates ) {
      this.filters['metrics']        = this.metrics;
      this.filterSelected['metrics'] = this.metrics[0];
    }
  }

  ngOnDestroy(){
    this.customerSubscribe.unsubscribe();
    this.surveySubscribe.unsubscribe();
    this.customersStateSubscribe.unsubscribe();
  }

  private endCall(){
    this.waiting    = false;
    this.firstCall  = false;
  }


  init(){
    this.waiting    = true;
    this.totals     = {
      'brands': {},
      'regions': {}
    };
    const date      = new FilterDate(this.currentDates.startDate, this.currentDates.endDate);
    const survey = this.store.selectSnapshot((state) => state.currentSurvey.currentSurvey);

    const customers = this.store.selectSnapshot(state => state.customers.relativeCurrentChain).map(c => c.id).join(',');

    const surveyIds = survey?.ids || '';
    const version = (survey?.survey[0] || {})?.version || 2;

    this.currentYear = new Date().getFullYear();
    this.previousYear = this.currentYear - 1;

    this.filters['areas']  = [];
    this.filtersTypes = ['metrics'];

    switch (this.filterSelected['metrics']) {
      case 'area':
        if ( customers.length ) {
        this.initAreas(date,customers,surveyIds, version);
        }
        break;
      case 'iro':
        if (customers.length) {
          this.initIro(customers, date);
        }
        break;
      default:
        if ( customers.length ) {
          this.initMetric(date,customers,surveyIds, version)
        }
        break;
    }
  }

  createData(result,average=false, rate=false){
    const element     = this.utilService.copyObject(result);
    const data        = new DataResponse();

    let suffix;
    let brands_key;
    let regions_key;
    if (!rate) {
      suffix      = average ? '_avg' : '';
      brands_key  = `brands${suffix}`;
      regions_key = `regions${suffix}`;
    } else {
      brands_key  = average ? 'average' : 'brands';
      regions_key = average ? 'average' : 'regions';
    }

    data.brands                       = element[0][brands_key];
    data.regions                      = element[0][regions_key];
    data.accumulated_brands           = element[1][brands_key];
    data.accumulated_regions          = element[1][regions_key];
    data.accumulated_previous_brands  = element[2][brands_key];
    data.accumulated_previous_regions = element[2][regions_key];

    return data;
  }

  initIro(customersIds, date) {
    const startDate = date.startDate;
    const endDate = date.endDate;
    forkJoin([
      this.chainExcutiveService.getExecutiveIRO(customersIds, startDate, endDate),
      this.chainExcutiveService.getExecutiveIROAccumulated(customersIds, this.currentYear),
      this.chainExcutiveService.getExecutiveIROAccumulated(customersIds, this.previousYear),
    ]).subscribe((response) => {
      const rate = false;
      const key = 'iro';
      this.data = this.createData(response, false, rate);
      this.calculateTotals(this.createData(response, true, rate), key, rate);
      this.createTable(this.data, key, rate);
      this.endCall();
    });
  }

  initAreas(dates:FilterDate,chain,surveyIds, version){
    forkJoin([
      this.chainExcutiveService.getExcutiveAreas(dates, chain, surveyIds, version),
      this.chainExcutiveService.getExcutiveAreasAccummulated(this.currentYear,chain,surveyIds, version),
      this.chainExcutiveService.getExcutiveAreasAccummulated(this.previousYear,chain,surveyIds, version),
    ]).subscribe((result:any)=>{
      const data = result[0];
      this.filters['areas'] = data.brands[0] ? [-1].concat(data.brands[0].areas.map(x=>x.area_id)) : [];
      this.filterSelected['areas'] = this.filters['areas'][0];
      this.filtersTypes = this.filtersTypes.concat(['areas']);
      this.data = this.createData(result);
      this.dataAverage = this.createData(result,true);
      this.endCall();
      this.calculateAverageAreas();
      this.filterByArea();
    })
  }

  initMetric(dates:FilterDate,chain,surveyIds, version){
    const key = this.getKeyData;
    let observable: Observable<any>;

    switch(this.sourceData) {
      case 'rates':
        observable = this.initMetricResponseRate(dates, chain, surveyIds, key, version);
        break;
      case 'nps':
        observable = this.initMetricNps(chain, surveyIds, dates, version, key);
        break;
      default:
        observable = this.initMetricDefault(dates, chain, surveyIds);
        break;
    }
    observable.subscribe(response => this.initMetricCallback(response, key, this.sourceData === 'rates'));
  }

  initMetricCallback(response, key, rate = false) {
    this.data = this.createData(response, false, rate);
    this.calculateTotals(this.createData(response,true, rate),key, rate)
    this.createTable(this.data,key, rate);
    this.endCall();
  }

  initMetricResponseRate(dates: FilterDate, customerIds, surveysIds, key, version) {
    return forkJoin([
      this.chainExcutiveService.getExecutiveMetricRateResponse(customerIds, surveysIds, dates, this.productId, version),
      this.chainExcutiveService.getExecutiveMetricRateResponseAcummulated(customerIds, surveysIds, this.currentYear, this.productId, version),
      this.chainExcutiveService.getExecutiveMetricRateResponseAcummulated(customerIds, surveysIds, this.previousYear, this.productId, version),
    ]);
  }

  initMetricNps(customerIds, surveysIds, dates, version, key) {
    return forkJoin([
      this.chainExcutiveService.getExecutiveNpsMetric(customerIds, surveysIds, dates, version),
      this.chainExcutiveService.getExecutiveNpsMetricAccumulated(customerIds, surveysIds, this.currentYear, dates, version),
      this.chainExcutiveService.getExecutiveNpsMetricAccumulated(customerIds, surveysIds, this.previousYear, dates, version),
    ]);
  }

  initMetricDefault(dates, chain, surveyIds) {
    return forkJoin([
      this.chainExcutiveService.getExcutiveMetric(this.sourceData,dates, chain, this.productId, surveyIds),
      this.chainExcutiveService.getExcutiveMetricAccumulated(this.sourceData,this.currentYear, chain, this.productId, surveyIds),
      this.chainExcutiveService.getExcutiveMetricAccumulated(this.sourceData,this.previousYear, chain, this.productId, surveyIds),
    ]);
  }

  get getKeyData(){
    if(this.filterSelected['metrics']==='semantic_positive') return 'positive_percentage';
    if(this.filterSelected['metrics']==='semantic_negative') return 'negative_percentage';
    return this.filterSelected['metrics'];
  }

  get sourceData(){
    if(this.filterSelected['metrics'] === 'semantic_index') return 'semantic_index';
    if(this.filterSelected['metrics']?.includes('semantic')) return 'semantic';
    if(['rate','answered','email_surveys','open_surveys'].includes(this.filterSelected['metrics'])) return 'rates';
    if(['sent_emails','email_rate'].includes(this.filterSelected['metrics'])) return 'pms';
    if(this.productId===3) return 'chains';
    return this.filterSelected['metrics'];
  }


  createTable(data,key_period, rate = false){
    this.dataSourceBrands  = new MatTableDataSource(this.getDataTable(data,key_period,'brands', rate));
    this.dataSourceRegions = new MatTableDataSource(this.getDataTable(data,key_period, 'regions', rate));
  }


  getDataTable(data,key_period, type: 'brands' | 'regions', rate){
    const dataTable: any[]= [];
    data[type].forEach((element)=>{
      let isRate = !rate ?  element[key_period] :  element['response_rate'][key_period];
      isRate = ['email_surveys', 'open_surveys'].includes(key_period) ? isRate['rate'] : isRate;

      const period             = (key_period === 'area' ? element.areas[0].rating : isRate) || new Period();
      const annualData         = (data[`accumulated_${type}`].find(x=>x.factor === element.factor)) || new Period();
      const annualPreviousData = (data[`accumulated_previous_${type}`].find(x=>x.factor === element.factor)) || new Period();

      let isRateCurrent = !rate ? annualData[key_period] : annualData['response_rate'][key_period];
      isRateCurrent  = ['email_surveys', 'open_surveys'].includes(key_period) ? isRateCurrent['rate'] : isRateCurrent;
      const annual_current     = (key_period === 'area' ? annualData.areas[0].rating : isRateCurrent) || new Period();

      let isRatePrevious = !rate ? annualPreviousData[key_period] :  annualPreviousData['response_rate'][key_period];
      isRatePrevious  = ['email_surveys', 'open_surveys'].includes(key_period) ? annualPreviousData['response_rate'][key_period]['rate'] : isRatePrevious;
      const annual_previous    = (key_period === 'area' ? annualPreviousData.areas[0].rating : isRatePrevious) || new Period();

      dataTable.push({
        'factor' :                  element.factor,
        'properties_quantity' :     element.properties_quantity,
        'average_period':           key_period === 'iro' ? period.actual.iro : period.actual,
        'average_period_previous':  key_period === 'iro' ? period.previous.iro : period.previous,
        'variation':                key_period === 'iro' ? period.variation.iro : period.variation,
        'acummulated' :             key_period === 'iro' ? annual_current.actual.iro : annual_current.actual,
        'acummulated_previous' :    key_period === 'iro' ? annual_previous.actual.iro : annual_previous.actual,
        'annual_variation' : key_period === 'iro' ? this.utilService.getVaration(annual_current.actual.iro,annual_previous.actual.iro,'none'): this.utilService.getVaration(annual_current.actual,annual_previous.actual,'none')
      })

    })

    return dataTable;
  }

  calculateTotals(averages, key_period, rate = false){
    this.totals = {
      'brands': this.calculateTotalsPeriods(averages,'brands',key_period, rate),
      'regions'  : this.calculateTotalsPeriods(averages,'regions',key_period, rate)
    }
  }

  calculateTotalsPeriods(element,type,key_period,rate = false){
    const isEmailOrSurvey = ['email_surveys', 'open_surveys'].includes(key_period);
    let isRate = !rate ? element[type][key_period] : element[type][0]['response_rate'][key_period];
    isRate = isEmailOrSurvey ? isRate['rate'] : isRate;

    const acummulatedKey          = `accumulated_${type}`;
    const acummulatedPreviousKey  = `accumulated_previous_${type}`;

    const period = (key_period==='area' ?  element[type].areas[0].rating : isRate) || new Period();
    let isAnnualCurrentRate = !rate ? element[acummulatedKey][key_period] : element[acummulatedKey][0]['response_rate'][key_period];
    isAnnualCurrentRate = isEmailOrSurvey ? isAnnualCurrentRate['rate'] : isAnnualCurrentRate;
    const annual_current  = (key_period==='area' ?  element[acummulatedKey].areas[0].rating : isAnnualCurrentRate) || new Period();

    let isAnnualPreviousRate = !rate ? element[acummulatedPreviousKey][key_period] : element[acummulatedKey][0]['response_rate'][key_period];
    isAnnualPreviousRate = isEmailOrSurvey ? isAnnualPreviousRate['rate'] : isAnnualPreviousRate;

    const annual_previous = (key_period==='area' ?  element[acummulatedPreviousKey].areas[0].rating : isAnnualPreviousRate) || new Period();

    console.log(key_period)
    return {
      'average_period':           key_period === 'iro' ? period.actual.iro : period.actual,
      'properties_quantity' :     !rate ? element[type].properties_quantity : element[type][0].properties_quantity,
      'average_period_previous':  key_period === 'iro' ? period.previous.iro : period.previous,
      'variation':                key_period === 'iro' ? period.variation.iro : period.variation,
      'acummulated' :             key_period === 'iro' ? annual_current.actual.iro : annual_current.actual,
      'acummulated_previous' :    key_period === 'iro' ? annual_previous.actual.iro : annual_previous.actual,
      'annual_variation' :        key_period === 'iro' ? this.utilService.getVaration(annual_current.actual.iro,annual_previous.actual.iro,'none') : this.utilService.getVaration(annual_current.actual,annual_previous.actual,'none')
    }

  }

  calculateAverageAreas(){
    Object.keys(this.data).forEach(key=>{
      this.data[key].forEach(element=>{
        getAverageAreas(element.areas)
      });
      getAverageAreas(this.dataAverage[key].areas);
    });
  }

  filterByArea(){
    const area        = this.filterSelected['areas'];
    const data        = this.utilService.copyObject(this.data);
    const dataAverage = this.utilService.copyObject(this.dataAverage);

    Object.keys(data).forEach(key=>{
      data[key].forEach(element => {
        element.areas        = element.areas.filter(x=>x.area_id === area);
      });
      dataAverage[key].areas  = dataAverage[key].areas.filter(x=>x.area_id === area);
    })

    this.createTable(data,'area');
    this.calculateTotals(dataAverage,'area');
  }

  setSelectlValue($event,key){
    this.filterSelected[key] = $event.value;
    if(this.filterSelected['metrics']==='area' && this.filters['areas'].length >0) {
      this.waiting = true;
      this.filterByArea();
      setTimeout(_ => {
        this.waiting = false;
      },300);
    }else{
      this.init();
    }
  }

  get listenCustomerChange(){
    return [3].includes(this.productId);
  }

  get showAreaFilter(){
    return this.filterSelected['metrics'] === 'area' && this.filters['areas'].length>0
  }


}
