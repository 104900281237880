import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { DateService } from 'app/shared/services/date.service';

@Injectable({
  providedIn: 'root',
})
export class IroService {
  constructor(private http: HttpClient, private dateService: DateService) {}

  getIro(customerId: number, startDate: string, endDate: string) {
    const url = `${environment.onlinePath}customers/${customerId}/iro/metrics`;
    const params = new HttpParams().set('start_date', startDate).set('end_date', endDate);
    return this.http.get(url, { params });
  }

  getRankingIro(customerId: number, startDate: string, endDate: string) {
    const url = `${environment.onlinePath}customers/${customerId}/iro/ranking`;
    const params = new HttpParams().set('start_date', startDate).set('end_date', endDate);
    return this.http.get(url, { params });
  }

  getRankingTop10(startDate: string, endDate: string) {
    const url = `${environment.onlinePath}benchmark/iro/ranking/averages`;
    const params = new HttpParams().set('start_date', startDate).set('end_date', endDate);
    return this.http.get(url, { params });
  }

  getRankingByCountry(countryCode: string, startDate: string, endDate: string) {
    const url = `${environment.onlinePath}benchmark/iro/countries/${countryCode}/averages`;
    const params = new HttpParams().set('start_date', startDate).set('end_date', endDate);
    return this.http.get(url, { params });
  }

  getRecommendation(customerId: number, startDate: string, endDate: string) {
    const url = `${environment.onlinePath}customers/${customerId}/iro/recommendations`;
    const params = new HttpParams().set('start_date', startDate).set('end_date', endDate);
    return this.http.get(url, { params });
  }

  getIroEvolution(customerId: number, startDate: string, endDate: string, compareCompetitors = false) {
    const segmentation = this.dateService.getSegmentation(startDate, endDate);
    const url = `${environment.onlinePath}customers/${customerId}/iro/trends`;
    const params = new HttpParams()
      .set('start_date', startDate)
      .set('end_date', endDate)
      .set('segmented_by', segmentation)
      .set('compare_competitors', compareCompetitors);
    return this.http.get(url, { params });
  }

  getIroChainMetrics(customersIds, startDate, endDate) {
    const url = `${environment.onlinePath}interchains/iro/metrics`;
    const params = new HttpParams()
      .set('start_date', startDate)
      .set('end_date', endDate)
      .set('customers_ids', customersIds);
    return this.http.get(url, { params });
  }
}
