import { Component, Inject, OnInit } from '@angular/core';
import { CoreLibModalComponent } from '../modal/modal.component';
import { DialogRef } from '../modal/dialog-ref';
import { DIALOG_DATA } from '../modal/dialog-tokens';
import { UtilService } from 'app/shared/util.service';
import { ProductId } from 'app/shared/enums';

@Component({
  selector: 'mh-core-lib-email-rate-modal',
  templateUrl: './email-rate-modal.component.html',
  styleUrls: ['./email-rate-modal.component.scss'],
})
export class CoreLibEmailRateModalComponent extends CoreLibModalComponent implements OnInit {
  coverageEmailStatus = '';
  coveragePhoneStatus = '';
  readonly PRODUCT_IDS = ProductId;
  constructor(protected dialogRef: DialogRef, @Inject(DIALOG_DATA) public data: any, private utilService: UtilService) {
    super(dialogRef, data);
  }

  ngOnInit(): void {
    this.coverageEmailStatus = this.utilService.qualifyPercent(this.data.pms.coverageEmail);
    this.coveragePhoneStatus = this.utilService.qualifyPercent(this.data.pms.coveragePhone);
  }

  close() {
    this.utilService.ga(
      'event',
      'click',
      (this.data.productId === this.PRODUCT_IDS.FollowUp ? 'followup' : 'onsite') + '-datamodal-close',
    );
    this.dialogRef.close();
  }

  get status() {
    return this.data.classes.state.replace('_', '-');
  }

  get type() {
    return this.data.type;
  }

  get tooltipTranslate() {
    return this.data.tooltipTranslate;
  }

  get currentDateFilter() {
    return this.data.currentFilter.label;
  }

  get validEmailPercentage() {
    return this.data.validEmailPercentage;
  }

  get guestsWithValidEmail() {
    return this.data.guestsWithValidEmail;
  }

  get totalGuests() {
    return this.data.totalGuests;
  }

  get emailsCount() {
    return this.data.emailsCount;
  }
}
