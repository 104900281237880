import { Component } from '@angular/core';
import { DateService } from 'app/shared/services/date.service';

@Component({
  selector: 'mh-online-summary-section',
  templateUrl: './online-summary-section.component.html',
  styleUrls: ['./online-summary-section.component.scss'],
})
export class OnlineSummarySectionsComponent {

  constructor(private dateService: DateService) {}

  handleDatepickerUpdated($event) {
    this.dateService.setCurrentFilter($event.currentFilter);
    this.dateService.setCurrentDates($event.currentDates);
  }

  get currentDates() {
    return this.dateService.currentDates;
  }

  get startDate() {
    return this.currentDates.startDate;
  }

  get endDate() {
    return this.currentDates.endDate;
  }

  get currentFilterDate() {
    return this.dateService.currentFilter;
  }
}
