import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilService } from 'app/shared/util.service';

@Component({
  selector: 'mh-evaluate-thumbs',
  templateUrl: './evaluate-thumbs.component.html',
  styleUrls: ['./evaluate-thumbs.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EvaluateThumbsComponent),
      multi: true,
    },
  ],
})
export class EvaluateThumbsComponent implements ControlValueAccessor {
  value: number = 0;
  @Input() showUpdateIcon = false;
  @Input() showCopyIcon = false;
  @Input() showConfigIcon = false;
  @Output() copyClicked = new EventEmitter<void>();
  @Output() updateClicked = new EventEmitter<void>();
  @Output() configClicked = new EventEmitter<void>();

  constructor(private utilService: UtilService) {}

  
  onChange: any = () => {};
  onTouched: any = () => {};

  
  toggleThumbsUp(): void {
    if (this.value === 1) {
      this.setValue(0); 
    } else {
      this.setValue(1);
      this.utilService.ga('','smart-reply-positive-evaluation');
    }
  }

  
  toggleThumbsDown(): void {
    if (this.value === -1) {
      this.setValue(0); 
    } else {
      this.setValue(-1);
      this.utilService.ga('','smart-reply-negative-evaluation');
    }
  }

  
  setValue(newValue: number): void {
    this.value = newValue;
    this.onChange(this.value); 
    this.onTouched();
  }

  
  writeValue(value: number): void {
    this.value = value || 0; 
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
  }

  handleConfigClicked() {
    this.configClicked.emit();
  }

  sendCopy() {
    this.copyClicked.emit();
  }

  sendRefresh() {
    this.updateClicked.emit();
  }
}
