import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { SmartRepliesModalComponent } from './smart-replies-modal/smart-replies-modal.component';
import { ConfigComponent } from './config/config.component';
import { SmartRepliesRoutingModule } from './smart-replies-routing.module';
import { SmartRepliesComponent } from './smart-replies.component';
import { SmartRepliesConfComponent } from './smart-replies-conf/smart-replies-conf.component';
import { ReplyTypeComponent } from './reply-type/reply-type.component';
import { ReplyAutomatizationConfComponent } from './reply-automatization-conf/reply-automatization-conf.component';
import { SeeExampleModalComponent } from './see-example-modal/see-example-modal.component';
@NgModule({
  declarations: [SmartRepliesModalComponent, ConfigComponent, SmartRepliesComponent, SmartRepliesConfComponent, ReplyTypeComponent, ReplyAutomatizationConfComponent, SeeExampleModalComponent],
  imports: [CommonModule, SharedModule, SmartRepliesRoutingModule],
})
export class SmartRepliesModule {}
