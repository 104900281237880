<div class="tw-flex">
    <button type="button" (click)="toggleThumbsUp()"
        class="tw-bg-transparent tw-p-2 tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-outline-none tw-transition tw-duration-200 tw-ease-in-out tw-border-none focus:tw-outline-none tw-text-gray-500">
        <i [class.fas]="value === 1" [class.far]="value !== 1"
            class="fa-thumbs-up tw-text-lg tw-border-none focus:tw-outline-none tw-outline-none tw-text-gray-500"></i>
    </button>

    <button type="button" (click)="toggleThumbsDown()"
        class="tw-bg-transparent tw-p-2 tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-outline-none tw-transition tw-duration-200 tw-ease-in-out tw-border-none focus:tw-outline-none tw-text-gray-500">
        <i [class.fas]="value === -1" [class.far]="value !== -1"
            class="fa-thumbs-down tw-text-lg tw-border-none focus:tw-outline-none tw-outline-none tw-text-gray-500"></i>
    </button>

    <button *ngIf="showUpdateIcon" type="button" (click)="sendRefresh()"
        class="tw-bg-transparent tw-p-2 tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-outline-none tw-transition tw-duration-200 tw-ease-in-out tw-border-none focus:tw-outline-none tw-text-gray-500">
        <i class="far fa-sync-alt tw-text-lg"></i>
    </button>

    <button *ngIf="showCopyIcon" type="button" (click)="sendCopy()"
        class="tw-bg-transparent tw-p-2 tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-outline-none tw-transition tw-duration-200 tw-ease-in-out tw-border-none focus:tw-outline-none tw-text-gray-500">
        <i class="far fa-copy tw-text-lg"></i>
    </button>

    <button *ngIf="showConfigIcon" type="button" (click)="handleConfigClicked()"
        class="tw-bg-transparent tw-p-2 tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-outline-none tw-transition tw-duration-200 tw-ease-in-out tw-border-none focus:tw-outline-none tw-text-gray-500">
        <i class="far fa-cog tw-text-lg"></i>
    </button>
</div>