import { Component, Inject, OnInit } from '@angular/core';
import { SmartRepliesService } from '../smart-replies.service';
import { DIALOG_DATA } from 'app/core-lib/dialogs/modal/dialog-tokens';
import { DialogRef } from 'app/core-lib/dialogs/modal/dialog-ref';
import { CoreLibModalComponent } from 'app/core-lib/dialogs/modal/modal.component';
import { FormControl } from '@angular/forms';
import { take } from 'rxjs/operators';
import { Clipboard } from '@angular/cdk/clipboard';
import { Store } from '@ngxs/store';
import { UtilService } from 'app/shared/util.service';
import { CustomersState } from 'app/states/customers/customers.state';
import { DefaultLanguageState } from 'app/states/default-language/default-language.state';
import { IHTTPResponse } from 'app/core-lib/interfaces/http-response';

@Component({
  selector: 'mh-smart-replies-modal',
  templateUrl: './smart-replies-modal.component.html',
  styleUrls: ['./smart-replies-modal.component.scss'],
})
export class SmartRepliesModalComponent extends CoreLibModalComponent implements OnInit {
  loading = false;
  edited = false;
  error = false;
  limitExceededError = false;
  reply = '';
  areaCtrol = new FormControl('');
  currentUser;

  constructor(
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
    private smartRepliesService: SmartRepliesService,
    private clipboard: Clipboard,
    private store: Store,
    private utilService: UtilService,
  ) {
    super(dialogRef, data);
  }

  ngOnInit(): void {
    this.loading = true;
    this.smartRepliesService.getAIReply(this.data.review.id).subscribe(
      (result: IHTTPResponse) => {
        if (result) {
          this.error = false;
          this.reply = result?.data?.answer?.length > 0 ? result.data.answer : '';
          this.areaCtrol.setValue(this.reply);
        }
        this.areaCtrol.valueChanges.pipe(take(1)).subscribe(() => (this.edited = true));
        this.loading = false;
      },
      (error) => {
        this.error = true;
        this.limitExceededError = error.errors[0].messages[0].text === 'Usage limit exceeded';
        this.loading = false;
      },
    );
    this.currentUser = this.utilService.currentUser;
  }

  copyReplyToClipboard() {
    this.clipboard.copy(this.areaCtrol.value);
  }

  goToComment(url: string) {
    window.open(url, '_blank');
  }

  goToFeedback(origin) {
    const originUrl =
      origin === 'limit'
        ? `https://surveys.myhotel.cl/survey-public/surveys-open/MxNxzgE4Scm3Wr_BSUvd4A`
        : `https://surveys.myhotel.cl/survey-public/surveys-open/oqsEoZZfTQC0nWJU4FX-ZA`;
    const customerState = this.store.selectSnapshot(CustomersState.currentCustomer);
    const langCode = this.store.selectSnapshot(DefaultLanguageState.languageCode);
    const queryParams = `?lang=${langCode}&name=${this.currentUser.name}&lastname=${this.currentUser.lastName}&email=${this.currentUser.email}&room=${customerState.name}&reservation_id=${customerState.chain_brand}&country=${customerState.country_code}&phone=${this.currentUser.companyPosition}`;
    window.open(originUrl + queryParams, '_blank');
  }
}
