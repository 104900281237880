import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateService } from 'app/shared/services/date.service';
import { OnlineSummaryService } from './interchain-online-summary.service';
import { ChartsDataSet } from 'app/shared/utils/charts-data-set';
import { UtilService } from 'app/shared/util.service';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { CustomersState } from 'app/states/customers/customers.state';
import { combineLatest, forkJoin, Observable, Subscription, throwError } from 'rxjs';
import { ProductId } from 'app/shared/enums';
import { auditTime, catchError, distinctUntilChanged, finalize, map } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { DateState } from 'app/states/date/date.state';
import { DefaultLanguageState } from 'app/states/default-language/default-language.state';
import { IroService } from 'app/new-fs/online/services/iro.service';

@Component({
  selector: 'mh-inter-chain-online-summary',
  templateUrl: './interchain-online-summary.compontent.html',
  styleUrls: ['./interchain-online-summary.component.scss'],
})
export class OnlineSummaryComponent implements OnInit, OnDestroy {
  @Select(CustomersState.fullCurrentChain) customersReady$: Observable<any[]>;
  @Select(CustomersState.currentCustomer) currentCustomer$: Observable<any>;
  @Select(DateState.currentDates) currentDates$: Observable<any>;
  @Select(DefaultLanguageState.languageId) languageReady$: Observable<any[]>;

  readonly PRODUCT_IDS = ProductId;
  lineChart;
  chainIds;
  countryCodes;
  currentCustomerId;
  currentCountryCode;
  data = {
    iro: null,
    semanticIndex: null,
    mainSummary: null,
    lineChart: null,
    ranking: null,
  };

  loading = {
    iro: true,
    semanticIndex: true,
    mainSummary: true,
    lineChart: true,
    ranking: true,
    rankingGlobal: true,
  };

  error = {
    iro: false,
    semanticIndex: false,
    mainSummary: false,
    lineChart: false,
    ranking: false,
  };

  subscriptions = new Subscription();
  constructor(
    private dateService: DateService,
    private onlineSummaryService: OnlineSummaryService,
    private chartsDataSet: ChartsDataSet,
    private utilService: UtilService,
    private translateService: TranslateService,
    private store: Store,
    private iroService: IroService,
  ) {}

  ngOnInit() {
    this.initListeners();
  }

  initListeners() {
    this.subscriptions = combineLatest([this.currentCustomer$, this.currentDates$, this.languageReady$])
      .pipe(distinctUntilChanged(), auditTime(1000))
      .subscribe(([customer]) => {
        this.currentCustomerId = customer.id ?? null;
        this.currentCountryCode = customer.country_code ?? null;
        const chain = this.store.selectSnapshot(CustomersState.fullCurrentChain);
        const uniqueCountryCodes = new Set(chain.map((hotel) => hotel.country_code));
        this.countryCodes = Array.from(uniqueCountryCodes);
        this.chainIds = chain.map((hotel) => hotel.id).join(',');
        this.fetchMetrics();
      });
  }

  private fetchMetrics() {
    this.fetchIro();
    this.fetchSemanticIndex();
    this.fetchMainSummary();
    this.fetchTrend();
    this.fetchRanking();
  }

  private loadData<T>(key: string, obs$: Observable<T>): Observable<T> {
    this.loading[key] = true;
    this.error[key] = false;
    return obs$.pipe(
      catchError((error) => {
        this.error[key] = error;
        return throwError(error);
      }),
      finalize(() => (this.loading[key] = false)),
    );
  }

  private updateLineChart(trendData) {
    if (trendData) {
      const endDate = DateTime.now().toISODate();
      const startDate = DateTime.now().minus({ months: 12 }).toISODate();

      const data = trendData.average.periods;
      const formattedData = {};
      for (const period of data) {
        formattedData[period.period] = {
          [1]: {
            score: period.iro,
            reviews: period.reviews,
            coverage: period.coverage,
            qualification: period.qualification,
          },
        };
      }
      const dataForGraph = this.chartsDataSet.parseTrendData(
        formattedData,
        startDate,
        endDate,
        [{ id: 1 }],
        this.utilService,
      );

      this.lineChart = { data: [], labels: [], colors: [] };
      this.lineChart.labels = dataForGraph.formatLabels;

      const types = ['score', 'reviews', 'coverage', 'qualification'];
      const hiddenTypes = ['reviews', 'coverage', 'qualification'];
      types.forEach((type, index) => {
        const lineDataSet = this.chartsDataSet.getLineData(
          dataForGraph.unit,
          dataForGraph.formatLabels,
          'none',
          'none',
          type,
        );

        const singleDataSet = lineDataSet.data[0];
        const singleColor = this.chartsDataSet.getUniqueColorFromIndex(index, false);

        this.lineChart.data.push({
          data: singleDataSet.data,
          hidden: hiddenTypes.includes(type),
          label: this.translateService.instant(`iro.iro.${type}`),
        });
        this.lineChart.colors.push(singleColor);
      });
      this.data.lineChart = this.lineChart;
    }
  }

  calculateRanking(rankingIRO, globalIRO, countryRankings) {
    const avgTop10 = {
      ...globalIRO,
      customer_dto: {
        name: 'top-10',
      },
      iro_detail_dto: { ...globalIRO.top_score },
    };
    const avgMyhotel = {
      ...globalIRO,
      customer_dto: {
        name: 'avg-myhotel',
      },
      iro_detail_dto: { ...globalIRO.average_score },
    };

    const rankingByCountry = countryRankings.map((countryRanking: { average_score: any }, index) => {
      return {
        customer_dto: {
          name: this.countryCodes[index],
          flag: this.countryCodes[index].toLowerCase(),
        },
        iro_detail_dto: { ...countryRanking.average_score },
      };
    });

    rankingIRO.push(...rankingByCountry);
    rankingIRO.push(avgTop10);
    rankingIRO.push(avgMyhotel);
    this.data.ranking = rankingIRO.sort((a: any, b: any) => b.iro_detail_dto.iro - a.iro_detail_dto.iro);
  }

  fetchIro() {
    this.loadData(
      'iro',
      this.onlineSummaryService
        .getInterChainIROMetricByCustomerIds(this.chainIds, this.startDate, this.endDate)
        .pipe(map((result) => result.average)),
    ).subscribe({
      next: (data) => {
        this.data.iro = data;
      },
      error: (err) => {
        console.error('Error loading IRO data', err);
      },
    });
  }

  fetchSemanticIndex() {
    this.loadData(
      'semanticIndex',
      this.onlineSummaryService.getInterChainSemanticIndexByCustomerIds(
        this.chainIds,
        this.PRODUCT_IDS.Online,
        this.startDate,
        this.endDate,
        true,
      ),
    )
      .pipe(map((result) => result.average))
      .subscribe({
        next: (data) => {
          this.data.semanticIndex = data;
        },
        error: (err) => {
          console.error('Error loading Index Semantic data', err);
        },
      });
  }

  fetchMainSummary() {
    this.loadData(
      'mainSummary',
      this.onlineSummaryService.getInterChainMainSummaryByCustomerIds(this.chainIds, this.startDate, this.endDate),
    )
      .pipe(map((result) => result.average))
      .subscribe({
        next: (data) => {
          this.data.mainSummary = data;
        },
        error: (err) => {
          console.error('Error loading Main Summary data', err);
        },
      });
  }

  fetchTrend() {
    const endDate = DateTime.now().toISODate();
    const startDate = DateTime.now().minus({ months: 12 }).toISODate();
    this.loadData(
      'lineChart',
      this.onlineSummaryService.getInterChainIROTrendByCustomerIds(this.chainIds, startDate, endDate, 'months'),
    ).subscribe({
      next: (data) => {
        this.updateLineChart(data);
      },
      error: (err) => {
        console.error('Error loading Trend data', err);
      },
    });
  }

  fetchRanking() {
    this.loading.rankingGlobal = true;
    const ranking$ = this.loadData(
      'ranking',
      this.onlineSummaryService.getInterChainRankingIROByCustomerIds(this.chainIds, this.startDate, this.endDate),
    );
    const global$ = this.loadData(
      'ranking',
      this.onlineSummaryService.getGlobalIRORanking(this.startDate, this.endDate),
    );

    const countryRankingsObs$ = [];
    this.countryCodes.forEach((countryCode) => {
      countryRankingsObs$.push(this.iroService.getRankingByCountry(countryCode, this.startDate, this.endDate));
    });

    forkJoin([ranking$, global$, ...countryRankingsObs$]).subscribe({
      next: ([ranking, global, ...countryRankings]) => {
        this.calculateRanking(ranking, global, countryRankings);
      },
      error: (err) => {
        console.error('Error loading Ranking data', err);
      },
      complete: () => {
        this.loading.rankingGlobal = false;
      },
    });
  }

  handleGetEbook() {
    const urls = {
      es: 'https://statics.myhotel.cl/documents/EBooks-iro/e-book+IRO+-+espan%CC%83ol.pdf',
      en: 'https://statics.myhotel.cl/documents/EBooks-iro/e-book+IRO+-+ingle%CC%81s.pdf',
      pt: 'https://statics.myhotel.cl/documents/EBooks-iro/e-book+IRO+-+portugue%CC%81s.pdf',
    };
    const lang = this.utilService.currentLanguage;
    window.open(urls[lang], '_blank');
  }

  get isHotel() {
    return !this.isNotHotel;
  }

  get isNotHotel() {
    return (
      this.utilService.customerIsRetail ||
      this.utilService.customerIsRestaurant ||
      this.utilService.customerIsClinic ||
      this.utilService.customerIsAirline
    );
  }

  get currentDates() {
    return this.dateService.currentDates;
  }

  get startDate() {
    return this.currentDates.startDate;
  }

  get endDate() {
    return this.currentDates.endDate;
  }

  get currentFilterDate() {
    return this.dateService.currentFilter;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
