import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IHTTPResponse } from 'app/core-lib/interfaces/http-response';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SmartRepliesUsage } from 'app/shared/model/answer-response.model';
import { IResponseCustomerConfSmartReply } from './models/IResponse';

@Injectable({
  providedIn: 'root',
})
export class SmartRepliesService {
  constructor(private http: HttpClient) {}

  patchLikeStatus(smart_reply_id, params) {
    return this.http.patch(`${environment.followupPath}smart-replies/${smart_reply_id}/like-status`, {}, { params });
  }

  getAIReply(reviewId) {
    const url = `${environment.onlinePath}reviews/${reviewId}/ia-reply`;
    return this.http.get<IHTTPResponse>(url);
  }

  getConfig(customerId) {
    const url = `${environment.customerPath}smart-replies/customers/${customerId}/config`;
    return this.http.get(url);
  }

  saveConfig(customerId, body) {
    const url = `${environment.customerPath}smart-replies/customers/${customerId}/config`;
    return this.http.post<IHTTPResponse>(url, body).pipe(map((response) => response.data));
  }

  getSmartReplyUsage(customerId): Observable<SmartRepliesUsage> {
    const url = `${environment.customerPath}smart-replies/customers/${customerId}/usage`;
    return this.http.get<IHTTPResponse>(url).pipe(map((response: IHTTPResponse) => response.data));
  }

  getCustomizeConf(customerId): Observable<IResponseCustomerConfSmartReply> {
    const url = `${environment.customerPath}smart-replies/customers/${customerId}/customize`;
    return this.http.get<IResponseCustomerConfSmartReply>(url);
  }

  postCustomizeConf(customerId, body) {
    const url = `${environment.customerPath}smart-replies/customers/${customerId}/customize`;
    return this.http.post<IHTTPResponse>(url, body);
  }

  postResponsibility(customerId, body) {

    const params = new HttpParams()
      .set('accepted', body.accepted); 

    const url = `${environment.customerPath}smart-replies/customers/${customerId}/responsibility`;
    return this.http.post<IHTTPResponse>(url, null, { params });
  }

  getTones() {
    const url = `${environment.customerPath}smart-replies/tones`;
    return this.http.get(url);
  }

  getExtensions() {
    const url = `${environment.customerPath}smart-replies/extensions`;
    return this.http.get(url);
  }

  getExample(params) {
    return this.http.get(`${environment.customerPath}smart-replies/example`, {
      params,
    });
  }
}
