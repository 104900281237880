<div class="excel_report {{class}}" [ngbTooltip]="(class=='small' ? ('reports.downloads.excel.small_tooltip' | translate) : false)" [hidden]="util.isMobile()" *ngIf="isPaid">
  <button class="btn btn-report btn-icon-right" [matMenuTriggerFor]="menu" [disabled]="type==='empty' || disableReport">
    <div [class.show]="waiting" class="spinner_wait">
      <mh-spinner-classic></mh-spinner-classic>
      <span class="downloading">{{'reports.downloads.downloading' | translate}}</span>
    </div>
    <i class="far fa-arrow-to-bottom tw-text-lg" [hidden]="type==='empty'" [class.waiting]="waiting"></i>
    <div class="content_text">
      <span [class.waiting]="waiting">
        {{ (type==='empty' ? 'commons.comming_soon_report' : 'reports.downloads.excel.create_report') | translate}}
      </span>
    </div>
  </button>

  <mat-menu #menu="matMenu">
    <button mat-menu-item  (click)="getReport()" *ngIf="!onlyEmail">
      <i class="fal fa-download"></i> {{'reports.downloads.excel.download_report' | translate}}
    </button>
    <button mat-menu-item (click)="getReport(true)" *ngIf="activeEmail">
      <i class="fal fa-envelope"></i> {{'reports.downloads.excel.send_report' | translate}}
    </button>
    <button mat-menu-item (click)="getReport(true, true)" *ngIf="type === 'chainOnlineSummary'">
      <i class="fal fa-envelope"></i> {{'reports.downloads.excel.review_online_report' | translate}}
    </button>
  </mat-menu>

</div>


