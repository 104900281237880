<mh-core-lib-generic-box customClass="tw-min-h-[305px] tw-pt-[25px] tw-px-[25px] tw-pb-[20px]">
  <mh-core-lib-loader-wrapper [loading]="loading" [type]="'spinner'" className="tw-text-3xl">
    <article *ngIf="!error && lineChars else errorContainer" class="tw-grid tw-grid-rows-[auto_1fr] tw-overflow-auto ">
      <h2 class="tw-p-2 tw-font-bold tw-text-xl tw-text-main-text" [innerHTML]="title | translate"></h2>
      <div style="display: block; height: 400px;">
      <canvas 
        width="400" height="400"
        baseChart 
        [datasets]="lineChars.data"
        [labels]="lineChars.labels"
        [options]="lineChartOptions"
        [colors]="lineChars.colors"
        [chartType]="'line'">
      </canvas>
      </div>
    </article>
  </mh-core-lib-loader-wrapper>
</mh-core-lib-generic-box>

<ng-template #errorContainer>
  <article class="tw-flex tw-flex-col error-container">
    <h2 class="tw-font-bold tw-text-xl tw-text-main-text" [innerHTML]="'iro.trends.title' | translate"></h2>
    <section class="tw-grid tw-grid-cols-2">
      <figure>
        <img class="tw-max-w-[232px]" src="https://mh-statics-dev-new.s3.sa-east-1.amazonaws.com/uploads/fidelity-suite/img/no+connection.png" alt="no connection">
      </figure>
      <article class="tw-grid tw-grid-rows-3 error-msg">
        <p class="tw-text-main-text tw-font-medium tw-row-start-2 tw-self-center tw-text-lg">
        {{'iro.commons.error' | translate}}
        </p>
        <button class="focus:tw-outline-0 tw-row-start-3 tw-text-main tw-font-medium tw-self-end tw-place-self-end tw-mt-1.5 tw-text-xl" (click)="retryFetch.emit()">{{'iro.commons.reload' | translate}}</button>
      </article>
    </section>
  </article>
</ng-template>
