<section class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 xl:tw-grid-cols-3 tw-grid-rows-[auto_auto_1fr] tw-gap-5">
  <mh-iro-widget class="tw-col-span-1 tw-h-full tw-flex tw-flex-col" [iro]="data.iro" [loading]="loading.iro"
    [error]="error.iro" *ngIf="isHotel" (getEbook)="handleGetEbook()" (retryFetch)="fetchIro()"></mh-iro-widget>
  <mh-semantic-index-widget class="tw-col-span-1 tw-h-full tw-flex tw-flex-col" [semanticIndex]="data.semanticIndex"
    [error]="error.semanticIndex" [loading]="loading.semanticIndex" [showSeeMore]="false"
    (retryFetch)="fetchSemanticIndex()"></mh-semantic-index-widget>
  <mh-ranking-iro-widget class="tw-row-span-3 tw-col-start-3 tw-h-full tw-flex tw-flex-col md:tw-block" *ngIf="isHotel"
    [ranking]="data.ranking" [loading]="loading.rankingGlobal" [error]="error.ranking" [customerId]="currentCustomerId"
    [currentCountry]="currentCountryCode" [showSeeMore]="false" (retryFetch)="fetchRanking()"></mh-ranking-iro-widget>
  <mh-dimensions-iro-widget class="tw-col-span-2 tw-row-start-2 tw-h-full tw-flex tw-flex-col"
    [dimensions]="data.mainSummary" [loading]="loading.mainSummary" [error]="error.mainSummary"
    (retryFetch)="fetchMainSummary()"></mh-dimensions-iro-widget>
  <mh-chart-widget class="tw-col-span-2 tw-row-start-3 tw-h-full tw-flex tw-flex-col" [lineChars]="data.lineChart"
    [error]="error.lineChart" [loading]="loading.lineChart" (retryFetch)="fetchTrend()"></mh-chart-widget>
</section>
