import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'mh-ranking-iro-widget',
  templateUrl: './ranking-iro-widget.component.html',
  styleUrls: ['./ranking-iro-widget.component.scss']
})
export class RankingIroWidgetComponent {
  @Input() ranking = [];
  @Input() customerId = null;
  @Input() currentCountry = null;
  @Input() loading: boolean;
  @Input() error: boolean;
  @Input() showSeeMore = true;
  @Output() retryFetch = new EventEmitter();

  constructor(private router: Router) {}

  goToBenchmark() {
    this.router.navigate(['online', 'comparacion'], {});
  }
}
