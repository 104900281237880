<div class="tw-gap-3 tw-flex column tw-flex-col">

    <mh-core-lib-actions-wrapper [hideContentBySlug]="true" [slug]="typeConfigSlug" class="tw-mt-3" >
        <mh-core-lib-generic-box>
            <mh-core-lib-loader-wrapper [loading]="loading" className="tw-text-[50px] tw-p-4  !tw-h-[300px] ">
                <mh-reply-type *ngIf="customizeConf" class="pt-3" [responseData]="customizeConf" [products]="productsList"
                    [extensions]="extensions" [tones]="tones">
                </mh-reply-type>
            </mh-core-lib-loader-wrapper>
        </mh-core-lib-generic-box>
    </mh-core-lib-actions-wrapper>



    <mh-core-lib-actions-wrapper [hideContentBySlug]="true" [slug]="productConfigSlug" >
        <mh-core-lib-generic-box>
            <mh-core-lib-loader-wrapper [loading]="loading" className="tw-text-[50px] tw-p-4  !tw-h-[300px] ">
                <mh-reply-automatization-conf *ngIf="customizeConf" [responseData]="customizeConf" [productsList]="productsList"
                    (userAcceptanceChange)="userAcceptance = $event" [accept_terms]="accept_terms">
                </mh-reply-automatization-conf>
            </mh-core-lib-loader-wrapper>
        </mh-core-lib-generic-box>
    </mh-core-lib-actions-wrapper>

</div>

