<mh-core-lib-generic-box customClass="tw-min-h-[305px] tw-pt-[25px] tw-px-[25px] tw-pb-[20px] {{loading ? 'tw-h-[305px]' : ''}} tw-max-h-[calc(100vh-135px)] tw-overflow-y-auto">
  <mh-core-lib-loader-wrapper [loading]="loading" [type]="'spinner'" className="tw-text-3xl">
    <article *ngIf="!error && ranking?.length else errorContainer" class="tw-grid tw-grid-rows-[60px_1fr] tw-gap-3">
      <h2 class="tw-font-bold tw-text-xl tw-text-main-text">🥇 {{'iro.ranking.title' | translate}}</h2>
      <section>
        <ol class="tw-font-medium tw-flex tw-flex-col tw-gap-3 tw-text-lg">
          <li *ngFor="let iroCustomer of ranking; let index = index" class="tw-flex tw-justify-between" [class.tw-text-main]="iroCustomer.customer_dto.id === customerId">
            <ng-container *ngTemplateOutlet="nameRanking; context: {index: index, name: (iroCustomer.customer_dto?.name || ''), flag: iroCustomer.customer_dto.flag} "></ng-container>
            <span>{{iroCustomer.iro_detail_dto?.iro || 0 | formatPercent}}</span>
          </li>
        </ol>
      </section>
      <section class="tw-grid tw-justify-end tw-mt-2" *ngIf="showSeeMore">
        <button type="button" class="tw-text-main tw-font-semibold focus:tw-outline-0 hover:tw-text-main-orange tw-transition-colors" (click)="goToBenchmark()">{{'iro.commons.see_more' | translate}}</button>
      </section>
    </article>
  </mh-core-lib-loader-wrapper>
</mh-core-lib-generic-box>


<ng-template #nameRanking let-name="name" let-index="index" let-flag="flag">
  <ng-container [ngSwitch]="name">
    <ng-container *ngSwitchCase="'top-10'">
      <p class="tw-flex tw-gap-1.5">
      <span>{{index + 1}}. {{'iro.ranking.top-10' | translate}}</span>
      <span class="hover:tw-cursor-pointer" mhToolTip [mhTooltip]="'iro.ranking.tooltips.top-10' | translate" [position]="'above'">🎖</span>
      </p>
    </ng-container>
    <ng-container *ngSwitchCase="'avg-myhotel'">
      <p class="tw-flex tw-gap-1.5">
      <span>{{index + 1}}. {{'iro.ranking.avg-myhotel' | translate}}</span>
      <span class="hover:tw-cursor-pointer" mhToolTip [mhTooltip]="'iro.ranking.tooltips.avg-myhotel' | translate" [position]="'above'">📈</span>
      </p>
    </ng-container>
    <ng-container *ngSwitchCase="currentCountry">
      <p class="tw-flex tw-gap-1.5">
      <span>{{index + 1}}. {{ name }}</span>
      <span [ngStyle]="{'width': 20+'px', 'height': 20+'px'}" class="flag-icon flag-icon-squared flag-icon-{{flag}} tw-rounded-full hover:tw-cursor-pointer tw-self-center" mhToolTip [mhTooltip]="'iro.ranking.tooltips.by-country' | translate: {country: currentCountry} " [position]="'above'"></span>
      </p>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngIf="!flag else country">
        <span>{{index + 1}}. {{name}}</span>
      </ng-container>
      <ng-container *ngIf="flag">
        <p class="tw-flex tw-gap-1.5">
          <span>{{index + 1}}. {{'countries.' + name | translate }}</span>
          <span *ngIf="flag" [ngStyle]="{'width': 20+'px', 'height': 20+'px'}" class="flag-icon flag-icon-squared flag-icon-{{flag}} tw-rounded-full hover:tw-cursor-pointer tw-self-center" mhToolTip [mhTooltip]="'iro.ranking.tooltips.by-country' | translate: {country: flag } " [position]="'above'"></span>
        </p>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #errorContainer>
  <article class="tw-flex tw-flex-col error-container">
    <h2 class="tw-font-bold tw-text-xl tw-text-main-text">🥇 {{'iro.ranking.title' | translate}}</h2>
    <section class="tw-grid tw-grid-cols-2">
      <figure>
        <img class="tw-max-w-[232px]" src="https://mh-statics-dev-new.s3.sa-east-1.amazonaws.com/uploads/fidelity-suite/img/no+connection.png" alt="no connection">
      </figure>
      <article class="tw-grid tw-grid-rows-3 error-msg">
        <p class="tw-text-main-text tw-font-medium tw-row-start-2 tw-self-center tw-text-lg">
        {{'iro.commons.error' | translate}}
        </p>
        <button class="focus:tw-outline-0 tw-row-start-3 tw-text-main tw-font-medium tw-self-end tw-place-self-end tw-mt-1.5 tw-text-xl" (click)="retryFetch.emit()">{{'iro.commons.reload' | translate}}</button>
      </article>
    </section>
  </article>
</ng-template>
