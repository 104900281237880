<div>
  <mh-core-lib-button
      class="report-button tw-flex tw-items-center"
      [isSecondary]="true"
      [isDownloading]="waiting"
      [text]="!waiting ? ('reports.downloads.excel.create_report' | translate) : ''"
      [height]="height"
      [width]="'tw-w-fit'"
      [customClass]="(customClass ? customClass : '') + ' !tw-shadow-none'"
      [textClass]="'tw-text-[15px] tw-font-[Rubik] tw-font-normal'"
      [icon]="'fal fa-arrow-to-bottom'"
      [customIconClass]="'tw-float-left tw-mr-2 tw-text-lg'"
      (clicked)="isOpen = !isOpen"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
      >
  </mh-core-lib-button>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayOffsetY]="!activeEmail ? -10 : 160"
  >
  <div class="tw-mt-5 tw-rounded-lg tw-bg-white tw-p-3 tw-shadow-1">
    <ul class="example-list">
      <li class="tw-py-2">
        <button type="button" class="tw-text-main-text hover:tw-text-main focus:tw-outline-0" (click)="getReport()">
          <i class="fal fa-download"></i> {{'reports.downloads.excel.download_report' | translate}}
        </button>
      </li>
      <li class="tw-py-2" *ngIf="activeEmail">
        <button class="tw-text-main-text hover:tw-text-main focus:tw-outline-0" (click)="getReport(true)" >
          <i class="fal fa-envelope"></i> {{'reports.downloads.excel.send_report' | translate}}
        </button>
      </li>
    </ul>
  </div>
</ng-template>

