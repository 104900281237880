import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mh-semantic-index-widget',
  templateUrl: './semantic-index-widget.component.html',
  styleUrls: ['./semantic-index-widget.component.scss']
})
export class SemanticIndexWidgetComponent {
  @Input() semanticIndex: number;
  @Input() loading = true;
  @Input() error: boolean;
  @Input() showSeeMore = true;
  @Output() retryFetch = new EventEmitter();
}
