import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DialogRef } from 'app/core-lib/dialogs/modal/dialog-ref';
import { DIALOG_DATA } from 'app/core-lib/dialogs/modal/dialog-tokens';
import { SmartRepliesService } from '../smart-replies.service';
import { UtilService } from 'app/shared/util.service';
import { catchError, finalize, tap } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { Language } from 'app/shared/model';

@Component({
  selector: 'mh-see-example-modal',
  templateUrl: './see-example-modal.component.html',
  styleUrls: ['./see-example-modal.component.scss'],
})
export class SeeExampleModalComponent {
  tones: any;
  extensions: any;
  types = [
    { id: 1, description: 'survey' },
    { id: 3, description: 'review' },
  ];
  details: any;
  exampleForm!: FormGroup;
  infoSelectedParam: any;
  loading: boolean;
  example_header: any;
  example_body: any;
  language_id: number | Language = null;
  readonly TRANSLATE_PREFIX = 'smart_replies.config.';
  source_content: string;
  subscriptions: Subscription = new Subscription()

  constructor(
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private utilService: UtilService,
    private smartRepliesService: SmartRepliesService,
  ) {
    this.tones = data.tones;
    this.extensions = data.extensions;
    this.infoSelectedParam = data.infoSelectedParam;
  }

  ngOnInit(): void {
    this.language_id = this.utilService.getLanguageId();
    
    this.exampleForm = this.fb.group({
      product_id: [null],
      tone_id: [null],
      extension_id: [null],
    });

    this.subscriptions.add(
      this.exampleForm.valueChanges.subscribe((formValues) => {
        this.getExamples(formValues);
      })
    )

    // followup and onsite are surveys, Onine review
    if (this.infoSelectedParam.product_id === 2) this.infoSelectedParam.product_id = 1;
    this.exampleForm.patchValue(this.infoSelectedParam);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handleClose() {
    this.dialogRef.close();
  }

  async getExamples(params) {
    params.language_id = this.language_id;
    this.loading = true;
    this.smartRepliesService
      .getExample(params)
      .pipe(
        tap((result: any) => {
          this.example_header = result.data.example_header;
          this.example_body = result.data.example_body;
          this.source_content = result.data.source_content;
        }),
        catchError((err) => {
          console.error('Error:', err);
          return of([]);
        }),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe();
  }

  get product_idValue() {
    return Number.parseInt(this.exampleForm.get('product_id')?.value) || null;
  }
}
