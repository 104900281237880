import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mh-iro-widget',
  templateUrl: './iro-widget.component.html',
  styleUrls: ['./iro-widget.component.scss'],
})
export class IroWidgetComponent {
  @Input() iro = null;
  @Input() loading: boolean;
  @Input() error: boolean;
  @Output() retryFetch = new EventEmitter();
  @Output() getEbook = new EventEmitter();
  showMore = false;
  get diameter() {
    return 120;
  }
  handleGetEbook() {
    this.getEbook.emit(true);
  }
}
