<div class="tw-flex tw-justify-center tw-h-full tw-flex-col tw-gap-10 tw-py-5 tw-px-32">
  <span class="tw-self-center tw-text-[28px] tw-font-medium tw-text-main-text">{{'core-lib.dialog.confirmation.delete_survey' | translate }}</span>

  <div class="tw-flex tw-gap-8">
    <span class="tw-text-[50px] tw-text-main-text">⚠️</span>
    <span class="tw-self-center tw-text-center tw-text-xl tw-font-medium tw-text-main-text" [innerHTML]="'core-lib.dialog.confirmation.delete_survey_description' | translate"></span>
  </div>

  <div class="tw-flex tw-flex-col tw-gap-2">
    <mh-select
      [customClasses]="'!tw-font-[Rubik] tw-border-gray-400'"
      [formGroup]="deleteForm"
      [keyControl]="'reason'"
      [options]="deleteReasonList"
      [placeholder]="'core-lib.dialog.confirmation.delete_survey_reason' | translate"
      [displayFn]="displayFn"
      [selectedOption]="deleteForm.value.reason"
      (optionChanged)="handleReasonChange($event)"
    >
    </mh-select>

    <mh-core-lib-textarea-input class="tw-mt-8" [placeholder]="'cases.detail_modal.insert_reason' | translate"
      [formGroup]="deleteForm" [keyControl]="'reasonComment'" [rows]="5"
      [inputClass]="'!tw-font-[Rubik] tw-border-gray-400 tw-resize-none'"
      *ngIf="['Otro', 'Other', 'Outro'].includes(deleteForm?.value.reason?.reason)"
    >
    </mh-core-lib-textarea-input>
  </div>

  <div class="tw-flex tw-justify-center tw-gap-5 tw-py-3 tw-mx-auto tw-overflow-hidden tw-mt-auto">
    <mh-core-lib-button 
      [isSecondary]="true" 
      [text]="'commons.back_button' | translate"
      [customClass]="'!tw-shadow-none'"
      [textClass]="'tw-font-[Rubik]'"
      (clicked)="handleCancel()"
    ></mh-core-lib-button>
    <mh-core-lib-button
      [isPrimary]="true"
      [text]="'core-lib.dialog.confirmation.delete_survey_button' | translate"
      [isDisabled]="deleteForm.invalid"
      [customClass]="'!tw-shadow-none !tw-bg-[#FF2020] disabled:!tw-bg-main-disabled'"
      [textClass]="'tw-font-[Rubik]'"
      width="180"
      (clicked)="handleDelete()"
    ></mh-core-lib-button>
  </div>
</div>
