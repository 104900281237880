import { Pipe, PipeTransform, Inject } from '@angular/core';
import { UtilService } from './util.service';
import { DomSanitizer, SafeHtml, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { DefaultLanguageState } from 'app/states/default-language/default-language.state';
import { DateTime } from 'luxon';

@Pipe({
  name: 'FilterPipe',
})
export class FilterPipe implements PipeTransform {
  transform(value: any, input: string) {
    if (input) {
      input = input.toLowerCase();
      return value.filter(function (el: any) {
        return (
          (el.guest.name.toLowerCase() + ' ' + el.guest.lastName.toLowerCase()).indexOf(input) > -1 ||
          el.guest.email.toLowerCase().indexOf(input) > -1
        );
      });
    }
    return value;
  }
}

@Pipe({
  name: 'QualifyScore',
})
export class QualifyScore implements PipeTransform {
  constructor(private utilService: UtilService) {}
  transform(value: number) {
    return this.utilService.qualifyScore(value);
  }
}

@Pipe({
  name: 'fill',
})
export class FillPipe implements PipeTransform {
  transform(value) {
    return new Array(value).fill(1);
  }
}

@Pipe({
  name: 'formatDate',
})
export class FormatDate implements PipeTransform {
  transform(value, full = false, show_day = true) {
    const stringDate = (value + '').replace(/\s/, 'T');
    let date: Date = new Date(stringDate);
    date = date.getFullYear() ? date : new Date(value); //bug safari
    const year = date.getFullYear();
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    const shortDate = show_day ? `${day}-${month}-${year}` : `${month}-${year}`;
    const fullDate = `${shortDate} <div class="hour"><i class="fal fa-clock inline"></i><span class="inline">${hour}:${minutes}</span></div>`;
    let formatDate = full ? fullDate : shortDate;
    formatDate = isNaN(year) ? '-' : formatDate;
    return `<div class="format_date">${formatDate}</div>`;
  }
}

@Pipe({
  name: 'formatScore',
})
export class FormatScore implements PipeTransform {
  constructor(private utilService: UtilService) {}
  transform(value, fixed = 1, showNull = false) {
    if (!value || showNull) return '-';
    return this.utilService.formatNumber(value, 'score', fixed);
  }
}

@Pipe({
  name: 'formatPercent',
})
export class FormatPercent implements PipeTransform {
  constructor(private utilService: UtilService) {}
  transform(value, round = true, scale = false, show_zero = false) {
    return this.utilService.formatPercent(value, round, scale, show_zero);
  }
}

@Pipe({
  name: 'formatCoverage',
})
export class FormatCoverage implements PipeTransform {
  constructor(private utilService: UtilService) {}
  transform(element, key) {
    const value = element[key];
    const total_of = element['total_of'];
    if (total_of) {
      if (total_of === 0) return '-';
    }
    return this.utilService.formatPercent(value, true, false, true);
  }
}

@Pipe({
  name: 'formatNumber',
})
export class FormatNumber implements PipeTransform {
  constructor(private utilService: UtilService) {}
  transform(value, format) {
    return this.utilService.formatNumber(value, format);
  }
}

@Pipe({
  name: 'formatNPS',
})
export class FormatNPS implements PipeTransform {
  constructor(private utilService: UtilService) {}
  transform(value, showNull = false) {
    if (value === null && showNull) return '-';
    return this.utilService.formatNPS(value);
  }
}

@Pipe({
  name: 'formatBy',
})
export class FormatBy implements PipeTransform {
  constructor(private utilService: UtilService) {}
  transform(value, type) {
    if (value === undefined || value === null) return '-';
    switch (type) {
      case 'nps':
        return this.utilService.formatNPS(value);
      case 'percent':
        return this.utilService.formatPercent(value, true, false, true);
      case 'pms':
        return this.utilService.formatPercent(value);
      case 'number':
        return this.utilService.formatNumber(value);
      case 'average':
        return this.utilService.formatDecimals(value);
      case 'rating':
        return this.utilService.formatDecimals(value);
      case 'ranking':
        return `#${value}`;
      case 'exec_time':
        return `${value}h`;
      default:
        return value;
    }
  }
}

//Este pipe oculta la info cuando no se ha pagado
@Pipe({
  name: 'validateShowData',
})
export class ValidateShowData implements PipeTransform {
  constructor(private utilService: UtilService) {}
  transform(value) {
    return this.utilService.validateShowData(value);
  }
}

//Este pipe oculta el nombre del hotel (live demo)
@Pipe({
  name: 'hideCurrentName',
})
export class HideCurrentName implements PipeTransform {
  constructor(private utilService: UtilService) {}
  transform(value) {
    return this.utilService.hideCurrentName(value);
  }
}

@Pipe({ name: 'camelToSnake' })
export class CamelToSnake implements PipeTransform {
  transform(value) {
    return value.replace(/([A-Z])/g, function ($1) {
      return '_' + $1.toLowerCase();
    });
  }
}

@Pipe({
  name: 'qualifyOnlineNPS',
})
export class QualifyOnlineNPS implements PipeTransform {
  constructor(private utilService: UtilService) {}
  transform(score: number) {
    return this.utilService.qualifyOnlineNps(score);
  }
}

@Pipe({
  name: 'protectEmail',
})
export class ProtectEmail implements PipeTransform {
  constructor(private utilService: UtilService) {}
  transform(email: string) {
    return this.utilService.protectEmail(email);
  }
}

@Pipe({
  name: 'protectQuestion',
})
export class ProtectQuestion implements PipeTransform {
  constructor(private utilService: UtilService) {}
  transform(label: string) {
    return this.utilService.protectQuestion(label);
  }
}

@Pipe({
  name: 'sanitizeHtml',
})
export class SanitizeHtmlPipe implements PipeTransform {
  constructor(
    @Inject(DomSanitizer)
    private _sanitizer: DomSanitizer,
  ) {}

  transform(v: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(v);
  }
}

@Pipe({
  name: 'safeUrl',
})
export class SafeUrlPipe implements PipeTransform {
  constructor(
    @Inject(DomSanitizer)
    private _sanitizer: DomSanitizer,
  ) {}
  transform(v: string): SafeUrl {
    return this._sanitizer.bypassSecurityTrustResourceUrl(v);
  }
}

@Pipe({
  name: 'safeStyle',
})
export class SafeStylePipe implements PipeTransform {
  constructor(
    @Inject(DomSanitizer)
    private _sanitizer: DomSanitizer,
  ) {}
  transform(v: string): SafeStyle {
    return this._sanitizer.bypassSecurityTrustStyle(v);
  }
}

@Pipe({
  name: 'waitHtml',
})
export class WaitHtml implements PipeTransform {
  constructor() {}
  transform(value: string, waiting = false, symbol = '-') {
    return waiting ? symbol : value;
  }
}

@Pipe({
  name: 'nameTranslate',
})
export class NameTranslate implements PipeTransform {
  constructor(private utilService: UtilService) {}
  transform(arrayTranslates, languageId) {
    return this.utilService.getNameTranslate(languageId, arrayTranslates);
  }
}

@Pipe({
  name: 'translateObject',
  pure: true,
})
export class TranslateObjectPipe implements PipeTransform {
  constructor(
    @Inject(TranslateService)
    private translateService: TranslateService,
    private store: Store,
  ) {}

  transform(translates: any[], valueKey = 'text', languageCode: string | null = null, languageKey = 'code'): string {
    if (!translates) {
      return '';
    }
    const language = languageCode || this.translateService.getDefaultLang();
    let translateObject = translates.find((t) => t.language[languageKey] === language) || {};
    if (valueKey && translateObject[valueKey] === '') {
      const defaultLanguage = this.store.selectSnapshot((state: DefaultLanguageState) => state['defaultLanguage'].code);
      translateObject = translates.find((t) => t.language[languageKey] === defaultLanguage) || {};
    }
    return translateObject[valueKey];
  }
}

@Pipe({
  name: 'shortNumber',
})
export class ShortNumberPipe implements PipeTransform {
  transform(number: number): any {
    if (isNaN(number)) return null; // will only work value is a number
    if (number === null) return null;
    if (number === 0) return null;
    let abs = Math.abs(number);
    const rounder = Math.pow(10, 1);
    const isNegative = number < 0; // will also work for Negetive numbers
    let key = '';

    const powers = [
      { key: 'Q', value: Math.pow(10, 15) },
      { key: 'T', value: Math.pow(10, 12) },
      { key: 'B', value: Math.pow(10, 9) },
      { key: 'M', value: Math.pow(10, 6) },
      { key: 'K', value: 1000 },
    ];

    for (let i = 0; i < powers.length; i++) {
      let reduced = abs / powers[i].value;
      reduced = Math.round(reduced * rounder) / rounder;
      if (reduced >= 1) {
        abs = reduced;
        key = powers[i].key;
        break;
      }
    }
    const ceroDecimal = key && abs === Math.round(abs) ? '.0' : '';
    return (isNegative ? '-' : '') + abs + ceroDecimal + key;
  }
}

@Pipe({
  name: 'hoursToDays',
})
export class HoursToDays implements PipeTransform {
  constructor(
    @Inject(TranslateService)
    private translateService: TranslateService,
  ) {}

  async transform(time) {
    if (!time) return '- hrs';
    let measure;
    if (time > 48) {
      time = time / 24;
      measure = await this.translateService.get('commons.days').toPromise();
    }
    measure = 'hrs';
    return `${parseInt(time)} ${measure}`;
  }
}

@Pipe({
  name: 'translatesTableHeader',
})
export class TranslatesTableHeaderPipe implements PipeTransform {
  transform(value: string): string {
    if (value === 'score_1' || value.includes('score_')) {
      return 'online.benchmark.table.general_score';
    }
    if (value.includes('ranking_ta')) {
      return `online.benchmark.table.${value}`;
    }
    const translateId = value.split('_')[1];
    return `online_areas.${translateId}`;
  }
}

@Pipe({
  name: 'filterDeleted',
})
export class FilterDeletedPipe implements PipeTransform {
  transform(value: { deleted: boolean }[], filter: boolean): any {
    // if checkbox is checked, show all values (active and inactive values)
    if (!filter === true) return value;
    // if checkbox is not checked, show values not deleted only (or active)
    if (filter === undefined || !filter === false) return value.filter((v) => !v.deleted);
  }
}

/*
 * Pipe para mostrar por consola datos que se manipulan en el componente
 * pero debiesen ser por un pipe para evitar un stackoverflow
 */
@Pipe({
  name: 'consoleLoop',
})
export class ConsoleLoopPipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    console.log(value, args);
  }
}

@Pipe({
  name: 'checkSelectedGenericFilter',
})
export class CheckSelectGenericFilterPipe implements PipeTransform {
  transform(filter, option, values) {
    if (Object.keys(values).length === 0) return false;
    if (typeof option === 'string') return (<any[]>(values[filter.name] || [])).includes(this.optionValue(option));
    return (<any[]>(values[filter.name] || [])).includes(option.value);
  }

  optionValue(option: any) {
    if (!option) return '';
    if (typeof option === 'string') return option;
    return (<any>option).value;
  }
}

@Pipe({
  name: 'hasErrorOnCheckbox',
  pure: false,
})
export class HasErrorOnCheckboxPipe implements PipeTransform {
  KEYS = [
    'info_use_name',
    'info_use_lastname',
    'info_use_email',
    'info_use_room',
    'info_use_reservation_id',
    'info_use_phone',
    'info_use_country',
    'info_use_checkin',
    'info_use_checkout',
    'info_use_guest_code',
  ];

  transform(control: any) {
    const hasError = this.KEYS.some((key) => {
      const hasError = control.get(key).errors;
      return hasError;
    });
    return hasError;
  }
}

@Pipe({
  name: 'diffDays',
})
export class DiffDaysPipe implements PipeTransform {
  transform(value) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const checkinToEvaluate = typeof value.checkin === 'string' ? new Date(value.checkin) : value.checkin;
    const checkoutToEvaluate = typeof value.checkout === 'string' ? new Date(value.checkout) : value.checkout;

    const utc1 = Date.UTC(checkinToEvaluate.getFullYear(), checkinToEvaluate.getMonth(), checkinToEvaluate.getDate());
    const utc2 = Date.UTC(checkinToEvaluate.getFullYear(), checkoutToEvaluate.getMonth(), checkoutToEvaluate.getDate());
    const diffDays = Math.floor((utc2 - utc1) / _MS_PER_DAY);
    return diffDays;
  }
}

@Pipe({
  name: 'checkDownloadStatus',
  pure: false,
})
export class CheckDownloadStatusPipe implements PipeTransform {
  transform(inputFile: any, filesStatus: any[]) {
    const file = filesStatus.find((fileStatus) => fileStatus.file_key === inputFile.file_key);
    return file?.downloading;
  }
}

@Pipe({
  name: 'getOtaLogo',
})
export class GetOtaLogoPipe implements PipeTransform {
  constructor(private utilService: UtilService) {}

  transform(otaId: string) {
    return this.utilService.getOtaLogo(otaId);
  }
}

@Pipe({
  name: 'replaceChar',
})
export class ReplaceCharPipe implements PipeTransform {
  transform(value, oldChar, newChar) {
    return value.replace(oldChar, newChar);
  }
}

@Pipe({
  name: 'replaceAllChar',
})
export class ReplaceAllCharPipe implements PipeTransform {
  transform(value, oldChar, newChar) {
    return value.replaceAll(oldChar, newChar);
  }
}

@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
  transform(value: any, locale = 'es', showHour = false) {
    const isoDate = typeof value === 'object' ? DateTime.fromJSDate(value).toISO() : DateTime.fromSQL(value).toISO();
    const format = showHour ? 'dd LLL yyyy HH:mm:ss' : 'dd LLL yyyy';
    return DateTime.fromISO(isoDate, { locale }).toFormat(format);
  }
}

@Pipe({
  name: 'millisecondsToUTC',
})
export class MillisecondsToUTCPipe implements PipeTransform {
  transform(value) {
    const ms_to_hours = 1000 * 60 * 60;
    const timezone = value / ms_to_hours;
    const neutralTimezone = Math.abs(timezone);

    if (neutralTimezone === 0) {
      // Timezone UTC 0
      return '00:00 GMT';
    }

    if (neutralTimezone < 10) {
      if (!Number.isInteger(neutralTimezone)) {
        return `${timezone < 0 ? '-' : ''}0${Math.floor(neutralTimezone)}:30 GMT`;
      }
      const utc = (timezone > 0 ? `0${neutralTimezone}:00` : `-0${neutralTimezone}:00`) + ' GMT';
      return utc;
    }

    if (neutralTimezone > 10) {
      const utc = (timezone > 0 ? `${neutralTimezone}:00` : `-${neutralTimezone}:00`) + ' GMT';
      return utc;
    }

    return timezone;
  }
}

@Pipe({
  name: 'calculateDays',
})
export class CalculateDaysPipe implements PipeTransform {
  private today = DateTime.now();
  constructor(@Inject(TranslateService) private translateService: TranslateService) {}

  async transform(value: any, trigger: string, dateType = 'sql') {
    if (!trigger) return;

    const checkoutDate =
      dateType === 'sql' ? DateTime.fromSQL(value).startOf('day') : DateTime.fromJSDate(value).startOf('day');

    const today = this.today.startOf('day');
    const diff = today.diff(checkoutDate, 'days').days;
    const fixedNumber = Math.abs(diff).toFixed();
    let translateKey = '';

    if (+fixedNumber === 0) {
      translateKey = 'answer_survey.today';
    } else if (diff === 1) {
      translateKey = 'answer_survey.yesterday';
    } else if (diff === -1) {
      translateKey = 'answer_survey.tomorrow';
    } else if (diff > 1) {
      translateKey = 'answer_survey.past_days';
    } else if (diff < -1) {
      translateKey = 'answer_survey.future_days';
    }

    const translate = await this.translateService.get(translateKey, { days: fixedNumber }).toPromise();
    return translate;
  }
}

@Pipe({
  name: 'typeof',
})
export class TypeofPipe implements PipeTransform {
  transform(value: string | number): string {
    return typeof value;
  }
}
