import { Component, Input, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SeeExampleModalComponent } from '../see-example-modal/see-example-modal.component';
import { ModalService } from 'app/core-lib/dialogs/modal/modal.service';
import { BreakpointService } from 'app/core-lib/services/breakpoints/breakpoint.service';

@Component({
  selector: 'mh-reply-type',
  templateUrl: './reply-type.component.html',
  styleUrls: ['./reply-type.component.scss']
})
export class ReplyTypeComponent {
  replyForm: FormGroup;
  readonly TRANSLATE_PREFIX = 'smart_replies.config.';
  @Input() responseData;
  @Input() products;
  @Input() extensions;
  @Input() tones;

  constructor(private fb: FormBuilder, private modalService: ModalService, private breakpointService: BreakpointService) {}

  ngOnInit() {
    this.replyForm = this.fb.group({
      customize_config: this.fb.array(
        this.products.map((product) =>
          this.fb.group({
            product_id: product.id, 
            tone_id: [null],
            extension_id: [null]
          })
        )
      )
    });

    this.replyForm.patchValue(this.responseData);
  }

  ngOnChanges({ responseData: {currentValue} }: SimpleChanges): void {
    this.replyForm?.patchValue(currentValue);
  }

  get productsFormArray() {
    return this.replyForm.get('customize_config') as FormArray;
  }

  get isMobileOrTablet() {
    return this.breakpointService.isMinorThanLg();
  }

  openExampleDialog(values) {     
    const classes = [
      '!tw-w-full',
      'tw-h-full',
      '!tw-max-w-full',
      '!tw-max-h-full',
      '!tw-rounded-none',
      'tw-overflow-hidden',
    ];
    
    const config = {
      panelClass: classes,
      data: {
        tones: this.tones,
        extensions: this.extensions,
        infoSelectedParam: values
      },
    };
    
    this.modalService.open(SeeExampleModalComponent, config, ['overlay-panel', 'no-padding', 'xxxlg', 'fit-height-content', 'tw-flex', 'tw-justify-center',]);
  }

}
