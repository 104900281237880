
    <section class="tw-pt-6 tw-px-4">
      <p class="tw-font-bold">{{ TRANSLATE_PREFIX + 'response_type_title' | translate }}</p>
      <p>{{ TRANSLATE_PREFIX + 'response_type_description' | translate }}</p>
    </section>
  
    <div class="tw-relative tw-overflow-x-auto tw-pt-3 tw-px-4 tw-py-5">
      <form [formGroup]="replyForm">
        <table class="tw-w-full tw-text-sm tw-text-left tw-min-w-full">
          <thead class="tw-text-xs tw-text-gray-400 tw-uppercase tw-font-bold">
            <tr class="tw-bg-white tw-border-b">
              <th scope="col" class="tw-px-6 tw-py-3 tw-w-1/6">{{TRANSLATE_PREFIX + 'module' | translate}}</th>
              <th scope="col" class="tw-px-6 tw-py-3 tw-w-1/6 tw-min-w-[135px]">{{TRANSLATE_PREFIX + 'tone_reply' | translate}}</th>
              <th scope="col" class="tw-px-6 tw-py-3 tw-w-1/4 tw-min-w-[300px]">{{TRANSLATE_PREFIX + 'extension_reply' | translate}}</th>
              <th scope="col" class="tw-px-6 tw-py-3 tw-w-auto tw-text-right"></th>
            </tr>
          </thead>
          <tbody formArrayName="customize_config">
            <tr *ngFor="let productGroup of productsFormArray.controls; let i = index" [formGroupName]="i" class="tw-bg-white tw-border-b">
              <th scope="row" class="tw-px-6 tw-py-4 tw-font-medium tw-text-gray-900 tw-whitespace-nowrap">
                {{ products[i].key }}
              </th>
              <td class="tw-py-4 tw-px-6">
                <select formControlName="tone_id" class="tw-bg-gray-100 tw-border-none tw-text-gray-900 tw-text-sm tw-rounded-lg tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-block tw-w-full tw-p-2.5">
                  <option *ngFor="let tone of tones" [value]="tone.id">{{  TRANSLATE_PREFIX + tone.description | translate }}</option>
                </select>
              </td>
              <td class="tw-py-4 tw-px-6">
                <select formControlName="extension_id" class="tw-bg-gray-100 tw-border-none tw-text-gray-900 tw-text-sm tw-rounded-lg tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-block tw-w-full tw-p-2.5">
                  <option *ngFor="let extension of extensions" [value]="extension.id">{{ TRANSLATE_PREFIX + extension.description | translate }}</option>
                </select>
              </td>
              <td class="tw-py-4 tw-text-right tw-px-6">
                <mh-core-lib-button [height]="38" [isSecondary]="true" [text]="(TRANSLATE_PREFIX +'see_example') | translate" (click)="openExampleDialog(productGroup.value)" ></mh-core-lib-button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  