<div class="tw-flex tw-flex-col">
  <span class="tw-font-medium tw-text-main-text">{{title}}</span>
  <ul class="tw-mt-2 {{ ulClass }}">
    <ng-container *ngFor="let option of options; trackBy: optionById">
      <li (click)="handleClick(option, checkInput)" 
          class="tw-py-0.5 checkbox-option tw-overflow-hidden tw-whitespace-nowrap tw-cursor-pointer {{ listClass }}"
          [ngClass]="{'tw-opacity-50 tw-pointer-events-none': option.disabled}">
        <input type="checkbox" #checkInput 
               class="tw-rounded tw-border-2 tw-border-main-text focus:tw-ring-0 focus:tw-ring-offset-0 tw-text-main tw-mr-2 tw-cursor-pointer tw-pointer-events-none {{ inputClass }}"
               [checked]="option[keySelected]"
               [disabled]="option.disabled">
        <label class="tw-font-medium tw-text-main-text tw-cursor-pointer {{ labelClass }}"
               [ngClass]="{'tw-cursor-not-allowed': option.disabled}">
          <ng-container *ngIf="displayHtml">
            <div class="tw-inline-block" [innerHTML]="displayOptionsFn(option)"></div>
          </ng-container>
          <ng-container *ngIf="!displayHtml">
            <ng-container *ngIf="displayOptionsFn">
              {{displayOptionsFn(option)}}
            </ng-container>
            <ng-container *ngIf="!displayOptionsFn">
              <ng-container *ngIf="needTranslate">
                {{translatePrefix + option[labelText] | translate}}
              </ng-container>
              <ng-container *ngIf="needObjectTranslate">
                {{option.translates | translateObject: 'name' }}
              </ng-container>
              <ng-container *ngIf="!needTranslate && !needObjectTranslate">
                <ng-container *ngIf="(option[labelText] | typeof) === 'string'">{{option[labelText] | titlecase}}</ng-container>
                <ng-container *ngIf="(option[labelText] | typeof) === 'number'">{{option[labelText]}}</ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </label>
      </li>
    </ng-container>
  </ul>
</div>
