import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ReportsService } from '../../reports.service';
import { UtilService } from '../../../shared/util.service';
import { NgbModal, NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as FileSaver from 'file-saver';
import { Subscription } from 'rxjs';
import { SummarySemanticService } from 'app/semantic/services/summary-semantic.service';
import { UsersService } from 'app/configuration/users/users.service';
import { TranslateService } from '@ngx-translate/core';
import { CorecLibSuccessModalComponent } from 'app/core-lib/dialogs/success-modal/success-modal.component';
import { ModalService } from 'app/core-lib/dialogs/modal/modal.service';

@Component({
  templateUrl: './download-excel-modal.component.html',
})
export class DownloadExcelModal {
  waiting = true;
  util;
  constructor(private utilService: UtilService, public activeModal: NgbActiveModal) {
    this.util = this.utilService;
  }
}

@Component({
  selector: 'mh-download-excel',
  templateUrl: './download-excel.component.html',
  styleUrls: ['./download-excel.component.scss'],
  providers: [ReportsService],
})
export class DownloadExcelComponent implements OnInit {
  @Input()
  initDate: string;

  @Input()
  endDate: string;

  @Input()
  type: string;

  @Input()
  class = '';

  @Input()
  activeEmail = false;

  @Input()
  chain = [];

  @Input()
  fromSemantic = false;

  @Input()
  fromUserEntries = false;

  @Input()
  isSingleCustomer = false;

  @Input()
  currentSurveyId;

  @Input()
  disableReport = false;

  @Input()
  responseRate = false;

  @Output()
  clickGetReport = new EventEmitter();

  onlyEmail = false;
  fileName = 'report.xlsx';
  waiting = false;
  modalRef: NgbModalRef;
  util;
  customerSubscribe: Subscription;
  dateSubscribe: Subscription;
  isPaid = true;

  constructor(
    private utilService: UtilService,
    private reportsService: ReportsService,
    private modalService: NgbModal,
    private coreLibModalService: ModalService,
    private semanticService: SummarySemanticService,
    private usersService: UsersService,
    private translateService: TranslateService,
  ) {
    this.util = this.utilService;
    this.customerSubscribe = this.utilService.isCustomerChanged().subscribe(() => {
      this.checkAccordingToProduct();
    });

    this.dateSubscribe = this.utilService.isCurrentDateChange().subscribe(() => {
      this.checkAccordingToProduct();
    });
  }

  ngOnInit() {
    this.checkAccordingToProduct();
  }

  ngOnChanges() {
    this.isPaid = this.utilService.getIsPaid();
  }

  checkAccordingToProduct() {
    const check = this.type && this.type.includes('chain');
    if (check) this.checkIfOnlyEmail();
  }

  getReport(sendEmail = false, isForOnlineReview = false, isTimeoutError = false) {
    this.waiting = true;
    this.clickGetReport.next();
    if (this.fromSemantic) {
      const email = sendEmail ? this.utilService.currentUser?.email || '' : '';
      this.semanticService
        .getReportSemantic(
          this.initDate,
          this.endDate,
          this.utilService.getCurrentLanguageId(),
          this.type,
          this.utilService.currentHotel.id,
          email,
        )
        .subscribe(
          (response) => {
            this.handleReportServiceResponse(response, email, isTimeoutError);
          },
          () => {
            this.handleReportTimeoutError();
            // this.errorAlert();
          },
        );
    } else if (this.fromUserEntries) {
      this.utilService.ga('report', 'download', this.type);
      this.usersService
        .getReportUserEntries(this.initDate, this.endDate, this.utilService.getCurrentLanguageId().toString())
        .subscribe(
          (response) => {
            this.downloadFile(response);
            this.waiting = false;
          },
          () => {
            this.errorAlert();
          },
        );
    } else if (this.responseRate) {
      let email: string = null;
      if (sendEmail) {
        email = this.utilService.currentUser.email;
      }
      this.utilService.ga('report', 'download', this.type);
      this.reportsService
        .responseRate(
          { startDate: this.initDate, endDate: this.endDate },
          this.utilService.getCurrentLanguageId().toString(),
          this.currentSurveyId,
          email,
        )
        .subscribe(
          (response) => {
            this.handleReportServiceResponse(response, email, isTimeoutError);
            this.waiting = false;
          },
          () => {
            this.handleReportTimeoutError();
          },
        );
    } else {
      const chain = this.chain || [];

      const dates = {
        startDate: this.initDate,
        endDate: this.endDate,
      };

      let email: any = undefined;

      if (sendEmail) {
        email = this.utilService.currentUser.email;
      }

      this.utilService.ga('report', 'download', this.type);
      const version = this.getSurveyVersion();
      if (this.isSingleCustomer && sendEmail) {
        this.reportsService[this.type](
          dates,
          this.utilService.currentHotel.id,
          this.utilService.getLanguageId(),
          email,
          version,
          this.currentSurveyId,
        ).subscribe(
          (response) => {
            this.handleReportServiceResponse(response, email, isTimeoutError);
          },
          () => {
            this.errorAlert();
          },
        );
      } else if (!this.isSingleCustomer) {
        const customerIds = chain.map((x: any) => x.id).join(',');
        const type = isForOnlineReview ? 'chainOnlineSummaryReview' : this.type;
        this.reportsService[type](
          dates,
          this.utilService.currentHotel.id,
          this.utilService.getLanguageId(),
          customerIds,
          email,
          version,
        ).subscribe(
          (response) => {
            this.handleReportServiceResponse(response, email, isTimeoutError);
          },
          () => {
            this.handleReportTimeoutError();
          },
        );
      } else {
        this.reportsService[this.type](
          dates,
          this.utilService.currentHotel.id,
          this.utilService.getLanguageId(),
          null,
          version,
          this.currentSurveyId,
        ).subscribe(
          (response) => {
            this.downloadFile(response);
          },
          () => {
            this.handleReportTimeoutError();
            // this.errorAlert();
          },
        );
      }
    }
  }

  handleReportServiceResponse(response, email, isError) {
    if (email) {
      this.waiting = false;
      const classes = ['overlay-panel', 'py-4', 'fit-height-content'];
      const config = {
        data: {
          title: this.translateService.instant('reports.alerts.send.success.success_title'),
          body: this.translateService.instant(
            isError ? 'reports.alerts.send.success.error_body' : 'reports.alerts.send.success.success_body',
            { email: email },
          ),
        },
      };
      const dialogRef = this.coreLibModalService.open(CorecLibSuccessModalComponent, config, classes);
    } else this.downloadFile(response);
  }

  handleReportTimeoutError() {
    this.getReport(true, false, true);
  }

  getSurveyVersion() {
    const currentSurvey = this.utilService.getCurrentSurvey(this.chain.map((client: any) => parseInt(client.id)));
    if (!currentSurvey || !currentSurvey.survey.length) return null;

    return currentSurvey.survey[0].version;
  }

  errorAlert() {
    this.utilService.sendAlert('errors.login.something_went_wrong');
    this.waiting = false;
  }

  downloadFile(response) {
    const url = response.awsUrl;
    this.fileName = response.name;
    let self = this;

    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';

    xhr.onreadystatechange = function () {
      setTimeout(() => {
        self.waiting = false;
      }, 0);
      if (xhr.readyState === 4 && xhr.status === 200) {
        const blob = new Blob([this.response], { type: 'application/vnd.ms-excel' });
        FileSaver.saveAs(blob, self.fileName);
      }
    };

    xhr.send();
  }

  showModal() {
    setTimeout(() => {
      this.modalRef = this.modalService.open(DownloadExcelModal, {
        size: 'lg',
      });
      this.modalRef.componentInstance.waiting = true;
    });
  }

  showActions() {
    return true;
  }

  checkIfOnlyEmail() {
    // const distanceDates = this.utilService.getDistanceDates(this.utilService.startDate, this.utilService.endDate) / 30;
    const distanceDates = this.utilService.getDistanceDates(this.initDate, this.endDate) / 30;
    const product = this.utilService.getProductById(this.utilService.currentProductId);
    const chain = this.utilService.getInnerChain(false, product);

    if (!this.activeEmail) {
      this.onlyEmail = false;
    } else {
      if (distanceDates > 6) {
        this.onlyEmail = true;
      } else if (distanceDates > 3 && chain.length > 10) {
        this.onlyEmail = true;
      } else if (chain.length > 20) {
        this.onlyEmail = true;
      } else {
        this.onlyEmail = false;
      }
    }
  }
}
