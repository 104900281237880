<label>
  <ng-container *ngIf="inner; else textPlain">
    <div [innerHtml]="label"></div>
  </ng-container>
  <ng-container [ngSwitch]="viewType">
    <!-- Toggle View -->
    <div *ngSwitchCase="'toggle'" class="tw-relative tw-flex tw-items-center tw-gap-3 tw-cursor-pointer">
      <input
        type="checkbox"
        class="tw-sr-only"
        [disabled]="isDisabled"
        [checked]="value"
        (click)="onClickEmit()"
      />
      <div
        class="tw-block {{ sizeToggle[size].container }} tw-rounded-full tw-border tw-border-gray-300 {{ color }}"
        [ngClass]="{ '!tw-bg-gray-200': isDisabled }"
      ></div>
      <div
        class="tw-absolute tw-border tw-border-gray-200 tw-top-[2px] tw-left-[2px] tw-bg-white {{ sizeToggle[size].toggle }} tw-rounded-full tw-transition"
        [ngClass]="{ 'tw-translate-x-3/4': value }"
      ></div>
      <span *ngIf="labelPosition === 'horizontal'" class="tw-font-normal transform tw-normal-case tw-text-base !tw-font-[Rubik]  {{ labelCheckboxClass }}">{{ label }}</span>
    </div>

    <!-- Checkbox View -->
    <div *ngSwitchCase="'checkbox'" class="tw-cursor-pointer" [ngClass]="{'tw-opacity-50 tw-pointer-events-none': isDisabled}" >
      <input
        type="checkbox"
        class="tw-rounded tw-border-2 tw-border-main-text focus:tw-ring-0 focus:tw-ring-offset-0 tw-text-main tw-mr-2 tw-cursor-pointer tw-w-6 tw-h-6"
        [checked]="value"
        [disabled]="isDisabled"
        (change)="onClickEmit()"
      />
      <span class="tw-font-normal transform tw-normal-case tw-text-base !tw-font-[Rubik]  {{ labelCheckboxClass }}">{{ label }}</span>
    </div>
  </ng-container>
</label>

<ng-template #textPlain>
  <p *ngIf="labelPosition === 'vertical'" >{{ label }}</p>
</ng-template>