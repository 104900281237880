<mh-core-lib-generic-box customClass="tw-min-h-[305px] tw-min-h-[305px] tw-h-[305px] tw-px-[25px] tw-pt-[25px] tw-pb-[20px]">
  <mh-core-lib-loader-wrapper [loading]="loading" [type]="'spinner'" className="tw-text-3xl">
    <article *ngIf="!error && semanticIndex else errorContainer" class="tw-grid tw-grid-rows-[1fr_2fr_1fr] tw-gap-3 loaded">
      <h2 class="tw-font-bold tw-text-xl tw-text-main-text">{{'iro.semantic_index.title' | translate}}</h2>
      <section class="tw-grid tw-grid-cols-[1fr_2fr] tw-min-h-[120px] tw-gap-2">
        <article class="tw-w-[120px]">
          <div class="tw-absolute tw-w-[120px] tw-h-[120px] tw-z-10 tw-grid tw-place-content-center tw-font-extrabold tw-text-[40px]">{{semanticIndex.semantic_index.actual | formatPercent:true:false:true}}</div>
          <div class="progress_box">
            <mat-progress-spinner class="graph graph_box"
                                  [strokeWidth]="15"
                                  [diameter]="120"
                                  [mode]="'determinate'"
                                  [value]="semanticIndex.semantic_index.actual">
            </mat-progress-spinner>

            <mat-progress-spinner class="graph total_graph"
                                  [strokeWidth]="15"
                                  [diameter]="120"
                                  [mode]="'determinate'"
                                  [value]="100">
            </mat-progress-spinner>
          </div>
        </article>
        <article class="tw-flex tw-flex-col tw-gap-1 2xl:tw-gap-3 tw-text-lg">
          <ng-container *ngTemplateOutlet="messages; context:{score: semanticIndex.semantic_index.actual}"></ng-container>
        </article>
      </section>
      <section class="tw-flex tw-justify-between">
        <p class="tw-text-[#c1c1c1] tw-self-end">
        <ng-container *ngTemplateOutlet="variation; context:{variation: semanticIndex.semantic_index.variation}"></ng-container>
        {{'iro.commons.previous_period_regard' | translate}}
        </p>
        <a [routerLink]="['/semantic/comments']" [queryParams]="{product: 'online'}" class="tw-self-end" *ngIf="showSeeMore">
          <span class="tw-font-bold tw-text-main hover:tw-text-main-orange tw-transition-colors">{{'iro.commons.see_more' | translate}}</span>
        </a>
      </section>
    </article>
  </mh-core-lib-loader-wrapper>
</mh-core-lib-generic-box>

<ng-template #messages let-score="score">
  <ng-container *ngIf="score >= 93">
    <div [innerHTML]="'iro.semantic_index.outstanding' | translate"></div>
  </ng-container>

  <ng-container *ngIf="score >= 88 && score < 93">
    <div [innerHTML]="'iro.semantic_index.acceptable' | translate"></div>
  </ng-container>

  <ng-container *ngIf="score > 0 && score < 88 ">
    <div [innerHTML]="'iro.semantic_index.average' | translate"></div>
  </ng-container>

  <ng-container *ngIf="score === 0">
    <div [innerHTML]="'iro.semantic_index.no_index' | translate"></div>
  </ng-container>
</ng-template>

<ng-template #errorContainer>
  <article class="tw-flex tw-flex-col error-container">
    <h2 class="tw-font-bold tw-text-xl tw-text-main-text">{{'iro.semantic_index.title' | translate}}</h2>
    <section class="tw-grid tw-grid-cols-2">
      <figure>
        <img class="tw-max-w-[250px]" src="https://mh-statics-dev-new.s3.sa-east-1.amazonaws.com/uploads/fidelity-suite/img/no+connection.png" alt="no connection">
      </figure>
      <article class="tw-grid tw-grid-rows-3 error-msg">
        <p class="tw-text-main-text tw-font-medium tw-row-start-2 tw-self-center tw-text-lg">
        {{'iro.commons.error' | translate}}
        </p>
        <button class="focus:tw-outline-0 tw-row-start-3 tw-text-main tw-font-medium tw-self-end tw-place-self-end tw-mt-1.5 tw-text-xl" (click)="retryFetch.emit()">{{'iro.commons.reload' | translate}}</button>
      </article>
    </section>
  </article>
</ng-template>

<ng-template #variation let-variation="variation">
  <span *ngIf="variation > 0" class="tw-text-promoters tw-text-sm tw-self-center tw-font-semibold">+{{variation | formatPercent:true:false:true}}</span>
  <span *ngIf="variation < 0" class="tw-text-detractors tw-text-sm tw-self-center tw-font-semibold">{{variation | formatPercent:true:false:true}}</span>
  <span *ngIf="variation === 0" class="tw-text-main-text tw-text-sm tw-self-center tw-font-semibold">{{variation | formatPercent:true:false:true}}</span>
</ng-template>
