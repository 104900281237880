<mh-alerts-wrapper>

  <section class="tw-flex tw-justify-between tw-items-center">
    <div>
      <mh-core-lib-datepicker
        [currentDates]="currentDates"
        [currentFilter]="currentFilterDate"
        (datepickerUpdated)="handleDatepickerUpdated($event)">
      </mh-core-lib-datepicker>
    </div>
    <div>
      <mh-filter-date
          [startDate]="startDate"
          [endDate]="endDate"
          [report]="fidelityPermisions.includes('interchain_tripadvisor_summary_report') ? 'chainOnlineTripadvisor' : null "
          [reportActiveEmail]="true"
          [checkPermissions]="true"
          slug="corporative_online_tripadvisor_report"
          >
      </mh-filter-date>
    </div>
  </section>
  <mh-box-element [title]="('inter-chain.online.tripadvisor.title'| translate )" *ngIf="fidelityPermisions.includes('interchain_tripadvisor_summary_result')">
    <widget-filter-chain-zone [productId]="productId" (chainChanged)="setChain($event)" [disabled]="!isReady"></widget-filter-chain-zone>
    <mh-inter-chain-tripadvisor [currentDates]="currentDates" [chain]="chainFilter" (isReady)="dataIsReady($event)"></mh-inter-chain-tripadvisor>
  </mh-box-element>

</mh-alerts-wrapper>
