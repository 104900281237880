<mh-core-lib-generic-box customClass="tw-min-h-[305px] tw-h-[305px] tw-px-[25px] tw-pt-[25px] tw-pb-[20px]">
  <mh-core-lib-loader-wrapper [loading]="loading" [type]="'spinner'" className="tw-text-3xl">
    <article *ngIf="!error && iro else errorContainer" class="tw-grid tw-gap-3 container-height" [ngClass]="{'tw-grid-rows-[1fr_2fr_1fr]': !showMore, 'tw-grid-rows-[auto_2fr_1fr]': showMore}">
      <ng-container *ngIf="!showMore else moreDetails">
        <h2 class="tw-font-bold tw-text-xl tw-text-main-text">{{'iro.iro.title' | translate}}</h2>
        <section class="tw-grid tw-grid-cols-[2fr_3fr_1fr] tw-min-h-[120px] tw-gap-2">
          <article class="tw-grid tw-place-content-center">
            <div class="tw-relative tw-h-[120px] tw-w-[120px]">
              <div class="tw-absolute tw-h-[120px] tw-w-[120px] tw-z-10 tw-grid tw-place-content-center tw-font-extrabold tw-text-[25px] lg:tw-text-[30px] xl:tw-text-[40px]">{{iro.details.actual.iro | formatPercent:true:false:true}}</div>
              <div class="progress_box">
                <mat-progress-spinner class="graph graph_box"
                                      [ngClass]="{'promoter': iro.details.actual.iro >= 80, 'detractor': iro.details.actual.iro < 60, 'passive': iro.details.actual.iro < 80 && iro.details.actual.iro >= 60}"
                                      [strokeWidth]="15"
                                      [diameter]="diameter"
                                      [mode]="'determinate'"
                                      [value]="iro.details.actual.iro">
                </mat-progress-spinner>

                <mat-progress-spinner class="graph total_graph"
                                      [strokeWidth]="15"
                                      [diameter]="diameter"
                                      [mode]="'determinate'"
                                      [value]="100">
                </mat-progress-spinner>
              </div>

            </div>
          </article>
          <article class="tw-grid tw-grid-cols-1 xl:tw-grid-cols-[1fr_2fr] tw-gap-0.5 xl:tw-gap-3 tw-place-content-center">
            <div class="xl:tw-text-lg tw-font-medium">{{'iro.iro.qualification' | translate}}</div>
            <div class="tw-bg-background-progress-bar tw-w-full tw-rounded-full tw-h-[10px] lg:tw-h-[13px] tw-place-self-center">
              <div [style.width]="(iro.details.actual.qualification ?? 0)+'%'" class="tw-bg-[#6bcaff] tw-rounded-full tw-h-[10px] lg:tw-h-[13px]"></div>
            </div>
            <div class="xl:tw-text-lg tw-font-medium">{{'iro.iro.coverage' | translate}}</div>
            <div class="tw-bg-background-progress-bar tw-w-full tw-rounded-full tw-h-[10px] lg:tw-h-[13px] tw-place-self-center">
              <div [style.width]="(iro.details.actual.coverage ?? 0)+'%'" class="tw-bg-[#6bcaff] tw-rounded-full tw-h-[10px] lg:tw-h-[13px]"></div>
            </div>
            <div class="xl:tw-text-lg tw-font-medium">{{'iro.iro.reviews' | translate}}</div>
            <div class="tw-bg-background-progress-bar tw-w-full tw-rounded-full tw-h-[10px] lg:tw-h-[13px] tw-place-self-center">
              <div [style.width]="(iro.details.actual.reviews ?? 0)+'%'" class="tw-bg-[#6bcaff] tw-rounded-full tw-h-[10px] lg:tw-h-[13px]"></div>
            </div>
          </article>
          <article class="tw-grid tw-grid-rows-3 tw-place-items-center">
            <span class="tw-text-lg tw-font-medium tw-justify-self-end">{{iro.details.actual.qualification | formatPercent:true:false:true}}</span>
            <span class="tw-text-lg tw-font-medium tw-justify-self-end">{{iro.details.actual.coverage | formatPercent:true:false:true}}</span>
            <span class="tw-text-lg tw-font-medium tw-justify-self-end">{{iro.details.actual.reviews | formatPercent:true:false:true}}</span>
          </article>
        </section>
        <section class="tw-flex tw-justify-between">
          <p class="tw-text-[#c1c1c1] tw-self-end">
          <ng-container *ngTemplateOutlet="variation; context:{variation: iro.details.variation.iro}"></ng-container>
          {{'iro.commons.previous_period_regard' | translate}}
          </p>
          <button type="button" class="focus:tw-outline-0 tw-self-end " (click)="showMore = true">
            <span class="tw-font-bold tw-text-main hover:tw-text-main-orange tw-transition-colors">{{'iro.commons.see_more' | translate}}</span>
          </button>
        </section>
      </ng-container>
    </article>
  </mh-core-lib-loader-wrapper>
</mh-core-lib-generic-box>

<ng-template #moreDetails>
  <h2 class="tw-font-bold tw-text-xl tw-text-main-text">{{'iro.iro.how.title' | translate}}</h2>
  <section class="tw-flex tw-flex-col tw-font-medium tw-gap-3">
    <p>
    {{'iro.iro.how.description' | translate}}
    </p>
    <ul>
      <li>{{'iro.iro.how.qualification' | translate}}</li>
      <li>{{'iro.iro.how.coverage' | translate}}</li>
      <li>{{'iro.iro.how.reviews' | translate}}</li>
      <li class="tw-mt-3">{{'iro.iro.how.total' | translate}}</li>
    </ul>
  </section>
  <section class="tw-flex tw-justify-between">
    <button type="button" (click)="handleGetEbook()" class="tw-font-bold tw-text-main focus:tw-outline-0 hover:tw-text-main-orange tw-transition-colors tw-self-end">{{'iro.iro.how.guide' | translate}}</button>
    <button type="button" class="focus:tw-outline-0 tw-self-end" (click)="showMore = false">
      <span class="tw-font-semibold tw-text-[#c1c1c1] hover:tw-text-main tw-transition-colors tw-self-end">{{'iro.commons.back' | translate}}</span>
    </button>
  </section>

</ng-template>

<ng-template #errorContainer>
  <article class="tw-flex tw-flex-col error-container">
    <h2 class="tw-font-bold tw-text-xl tw-text-main-text">Índice de Reputación Online (IRO)</h2>
    <section class="tw-grid tw-grid-cols-2">
      <figure>
        <img class="tw-max-w-[232px]" src="https://mh-statics-dev-new.s3.sa-east-1.amazonaws.com/uploads/fidelity-suite/img/no+connection.png" alt="no connection">
      </figure>
      <article class="tw-grid tw-grid-rows-3 error-msg">
        <p class="tw-text-main-text tw-font-medium tw-row-start-2 tw-self-center tw-text-lg">
        {{'iro.commons.error' | translate}}
        </p>
        <button class="focus:tw-outline-0 tw-row-start-3 tw-text-main tw-font-medium tw-self-end tw-place-self-end tw-mt-1.5 tw-text-xl" (click)="retryFetch.emit()">{{'iro.commons.reload' | translate}}</button>
      </article>
    </section>
  </article>
</ng-template>

<ng-template #variation let-variation="variation">
  <span *ngIf="variation > 0" class="tw-text-promoters tw-text-sm tw-self-center tw-font-semibold">+{{variation ?? 0 | formatPercent:true:false:true}}</span>
  <span *ngIf="variation < 0" class="tw-text-detractors tw-text-sm tw-self-center tw-font-semibold">{{variation ?? 0 | formatPercent:true:false:true}}</span>
  <span *ngIf="variation === 0 || !variation" class="tw-text-main-text tw-text-sm tw-self-center tw-font-semibold">{{variation ?? 0 | formatPercent:true:false:true}}</span>
</ng-template>
