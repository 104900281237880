<article
  class="tw-flex tw-flex-col tw-content-around tw-justify-around tw-p-8 tw-relative tw-bg-white tw-shadow-lg tw-rounded-lg tw-overflow-hidden tw-w-full tw-h-full tw-mx-auto">
  <!-- Close button -->
  <i class="far fa-times fa-lg tw-cursor-pointer tw-absolute tw-right-4 tw-top-3 tw-text-gray-500" (click)="close()"></i>

  <!-- Title -->
  <section class="tw-py-4 tw-text-center tw-rounded-t-lg">
    <h1 class="tw-text-2xl tw-font-semibold tw-text-gray-800">{{ 'followup.summary.emails-pms.title' | translate }}</h1>
  </section>

  <!-- Description -->
  <section class="tw-px-8 tw-py-4 tw-text-center tw-text-gray-600 tw-text-lg tw-w-full"
    [mhTooltip]="'followup.summary.emails-pms.tooltips.modal_qualify' | translate">
    <p class="tw-text-center">
      <span class="tw-font-bold !tw-font-[Rubik]">{{ data.hotelName }}</span> {{ 'followup.summary.emails-pms.modal.has_a' | translate }}
      <span class="tw-font-bold tw-font-[Rubik]" [ngClass]="{
      'tw-text-[#2BBB39]': ['good', 'very-good'].includes(status),
      'tw-text-[#F07E28]': ['regular'].includes(status),
      'tw-text-[#FF2020]': ['bad', 'very-bad'].includes(status),
    }">{{ data.pms.coverage | formatPercent: true:false:true }}</span>
      {{ type === 'restaurant' ? ('followup.summary.emails-pms.modal.valid_clients' | translate: { guests:
      (tooltipTranslate.guests | translate) }) : ('followup.summary.emails-pms.modal.valid_guests' | translate: {
      guests: (tooltipTranslate.guests | translate) }) }}
      <span class="tw-font-bold !tw-font-[Rubik]">{{ currentDateFilter | translate }}</span>.
    </p>
  </section>

  <section class="tw-px-6 tw-py-4 tw-bg-white tw-mx-auto tw-w-full">
    <table class="tw-w-full tw-text-left tw-border-collapse">
      <thead>
        <tr>
          <th class="tw-py-2 tw-border-b tw-font-medium tw-text-gray-700">{{ currentDateFilter | translate }}</th>
          <th class="tw-py-2 tw-border-b tw-font-medium tw-text-gray-700 tw-text-center">{{
            'followup.summary.emails-pms.modal.total_guests' | translate }}</th>
          <th class="tw-py-2 tw-border-b tw-font-medium tw-text-gray-700 tw-text-center">{{
            'followup.summary.emails-pms.modal.actual' | translate }}</th>
          <th class="tw-py-2 tw-border-b tw-font-medium tw-text-gray-700 tw-text-center">{{
            'followup.summary.emails-pms.modal.valid' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="tw-py-2 tw-text-gray-800 tw-border-b">{{ 'followup.summary.emails-pms.modal.with_email' | translate }}</td>
          <td class="tw-py-2 tw-text-center tw-text-gray-800 tw-border-b">{{ data.totalGuests }}</td>
          <td class="tw-py-2 tw-text-center tw-text-gray-800 tw-border-b" position="left"
            [mhTooltip]="('followup.summary.emails-pms.tooltips.total_emails' | translate:{pms: (tooltipTranslate.pms | translate), guests: (tooltipTranslate.guests | translate)})">
            {{ data.pms.emailsCount }}</td>
          <td class="tw-py-2 tw-text-center tw-border-b" position="right"
            [mhTooltip]="('followup.summary.emails-pms.tooltips.valid_total_emails' | translate:{pms: (tooltipTranslate.pms | translate), guests: (tooltipTranslate.guests | translate)})">
            <span class="tw-font-[Rubik]">{{ data.pms.emailsValid }}</span>
            <span class="tw-font-[Rubik]" [ngClass]="{
              'tw-text-green-500': data.pms.coverageEmail >= 50,
              'tw-text-red-500': data.pms.coverageEmail < 50
            }">
              ({{ data.pms.coverageEmail | formatPercent: true:false:true }})
            </span>
          </td>
        </tr>
        <tr>
          <td class="tw-py-2 tw-text-gray-800">{{ 'followup.summary.emails-pms.modal.with_mobile_phone' | translate }}
          </td>
          <td class="tw-py-2 tw-text-center tw-text-gray-800">{{ data.totalGuests }}</td>
          <td class="tw-py-2 tw-text-center tw-text-gray-800" position="left"
            [mhTooltip]="('followup.summary.emails-pms.tooltips.total_phones' | translate:{pms: (tooltipTranslate.pms | translate), guests: (tooltipTranslate.guests | translate)})">
            {{ data.pms.phonesCount }}</td>
          <td class="tw-py-2 tw-text-center" position="right"
            [mhTooltip]="('followup.summary.emails-pms.tooltips.valid_total_phones' | translate:{pms: (tooltipTranslate.pms | translate), guests: (tooltipTranslate.guests | translate)})">
            <span class="tw-font-[Rubik]">{{ data.pms.phonesValid }}</span>
            <span class="tw-font-[Rubik]" [ngClass]="{
              'tw-text-green-500': data.pms.coveragePhone >= 50,
              'tw-text-red-500': data.pms.coveragePhone < 50
            }">
              ({{ data.pms.coveragePhone | formatPercent: true:false:true }})
            </span>
          </td>
        </tr>
      </tbody>
    </table>

  </section>

  <section class="tw-mt-4 tw-bg-yellow-100 tw-px-10 tw-h-16 tw-rounded-lg tw-flex tw-items-center tw-gap-6">
    <span class="tw-text-2xl">🚀</span>
    <p class="tw-text-sm tw-font-[Rubik]">{{ 'followup.summary.emails-pms.modal.text_improve' | translate }}</p>
  </section>

</article>
