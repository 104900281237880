import { Component } from '@angular/core';
import { NotificationsService } from '../notifications.service';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from '../../shared/util.service';
import { AdminAlertsService } from '../../../app/admin/alerts/admin-alerts.service';
import { ModalService } from 'app/core-lib/dialogs/modal/modal.service';
import { CoreLibNotificationModalComponent } from 'app/core-lib/dialogs/notification-modal/notification-modal.component';
import { CoreLibDoubtCollectionModalComponent } from 'app/core-lib/dialogs/doubt-collection-modal/doubt-collection-modal.component';
import { Router, NavigationEnd } from '@angular/router';
import { find } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mh-flags',
  templateUrl: './flags.component.html',
  styleUrls: ['./flags.component.scss'],
  providers: [AdminAlertsService],
})
export class FlagsComponent {
  subs: Subscription;
  modalRef: NgbModalRef;
  busy = false;
  index = 0;
  util;
  refreshed = true;
  distance = 0;
  type_alert = false;
  currentSection;
  response_alert = [];

  whiteList = ['/login', '/logout', '/soporte', '/customer/configuracion/hotel/edit', '/admin/alertas'];

  constructor(
    private utilService: UtilService,
    private notificationsService: NotificationsService,
    public adminAlertsService: AdminAlertsService,
    private newModalService: ModalService,
    private router: Router,
  ) {
    this.util = this.utilService;
    this.subs = this.notificationsService.hasModalAlerts().subscribe((response) => {
      this.showModal(response);
    });
    this.subs.add(
      this.utilService.isCustomerChanged().subscribe(() => {
        this.showModal('customer');
      }),
    );
  }

  ngOnInit() {
    this.checkFlagsTypes();
  }

  showModal(from) {
    if (from === 'guard') {
      this.subs.add(
        this.router.events.pipe(find((event: NavigationEnd) => event instanceof NavigationEnd)).subscribe((event) => {
          this.checkFlagsTypes(event.url);
        }),
      );
      return;
    }
    this.checkFlagsTypes();
  }

  checkFlagsTypes(url = this.router.url) {
    this.utilService.setIsPaid(true);

    this.type_alert = false;

    this.utilService.setCurrentSection(url);
    const currentSection = url;
    if (
      this.utilService.getProductByName(currentSection) !== undefined &&
      this.utilService.getProductByName(currentSection) !== null &&
      this.notificationsService.getResponseAlert() !== ''
    ) {
      const customer = this.utilService.getCurrentHotel();
      this.currentSection = this.utilService.getProductByName(currentSection);
      this.response_alert = JSON.parse(this.notificationsService.getResponseAlert());

      if (this.response_alert !== null) {
        this.response_alert.forEach((element: any) => {
          element.products_ids.forEach((product) => {
            if (product.toString() === this.currentSection) {
              if (element.alert_property_type.name === 'blocking') {
                this.utilService.setIsPaid(false);
                this.utilService.sendIsPaid(false);
              } else {
                this.utilService.setIsPaid(true);
                this.utilService.sendIsPaid(true);
              }

              this.type_alert = element;
              if (
                !this.notificationsService.modalBussy &&
                this.type_alert &&
                this.utilService.currentUser &&
                !this.whiteList.includes(currentSection)
              ) {
                if (!this.busy) {
                  this.notificationsService.modalBussy = true;

                  setTimeout(() => {
                    this.distance = this.utilService.getDistanceDates(
                      customer.integration_status_dto.last_update,
                      new Date(),
                    ) || 1;
                    const config = {
                      data: {
                        type: element,
                        user: this.utilService.currentUser,
                        distance: this.distance,
                        navigateInvoice: () => {
                          this.router.navigate(['/invoice']);
                        },
                      },
                    };
                    const classes = ['overlay-panel', 'notification', 'no-padding'];

                    const backdropClasses = ['overlay-backdrop-notification'];

                    const paymentModalIds = [4, 5];
                    const modalToOpen: any = paymentModalIds.includes(element.translate[0].alert_id)
                      ? CoreLibDoubtCollectionModalComponent
                      : CoreLibNotificationModalComponent;
                    if (!paymentModalIds.includes(element.translate[0].alert_id)) {
                      classes.push('xl', 'h-auto');
                    } else {
                      classes.push('lg');
                    }

                    const ref = this.newModalService.open(modalToOpen, config, classes, backdropClasses);
                    ref.afterClosed().subscribe(() => {
                      this.notificationsService.modalBussy = false;
                    });
                  });
                }
              }
            }
          });
        });
      }
    }
  }

  currentSectionIs(section, from) {
    const current = this.utilService.currentSection;
    if (from === 'change_route' || this.refreshed) {
      this.refreshed = false;
      return current.includes(section);
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}

@Component({
  templateUrl: './flags-modal.component.html',
  styleUrls: ['../../../assets/scss/notifications/flags.scss'],
})
export class FlagsModalComponent {
  type: string;
  util;
  distance = 0;
  constructor(public activeModal: NgbActiveModal) {}
}
