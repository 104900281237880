import { Overlay, ComponentType } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { take } from 'rxjs/operators';
import { DialogRef } from './dialog-ref';
import { DIALOG_DATA } from './dialog-tokens';

export interface DialogConfig {
  data?: any;
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private openDialogs: DialogRef[] = [];

  constructor(
    private overlay: Overlay,
    private injector: Injector
  ) {}

  open<T>(component: ComponentType<T>, config?: DialogConfig, panelClass: string | string[] = 'overlay-panel', backdropClass: string | string[] = 'overlay-backdrop'): DialogRef {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: true,
      backdropClass,
      panelClass,
    });

    const dialogRef = new DialogRef(overlayRef);
    this.openDialogs.push(dialogRef);

    const injector = Injector.create({
      parent: this.injector,
      providers: [
        { provide: DialogRef, useValue: dialogRef },
        { provide: DIALOG_DATA, useValue: config?.data },
      ],
    });

    const portal = new ComponentPortal(component, null, injector);
    overlayRef.attach(portal);
    if (config?.data?.noCloseOnBackdropClick) return dialogRef; // No close on backdrop click

    dialogRef.closeOnEscapeKey(); // Close on escape key
    overlayRef.backdropClick().pipe(take(1)).subscribe(_ => dialogRef.close()); // Close on backdrop click
    return dialogRef;
  }

  closeAll() {
    this.openDialogs.forEach(dialog => dialog.close());
    this.openDialogs = [];
  }
}
