<div class="tw-max-w-7xl">

  <mh-core-lib-actions-wrapper [hideContentBySlug]="true" [slug]="answerLimitSlug" >
    <mh-core-lib-generic-box class="tw-max-w-lg tw-block">
      <section class="tw-pt-6 tw-px-4">
        <p class="tw-font-bold">{{ 'smart_replies.config.limit-ans-title' | translate }}</p>
      </section>
      <div class="tw-p-6">
        <mh-core-lib-loader-wrapper type="spinner" [loading]="loading" spinnerClassName="tw-text-[50px]"
          className="tw-self-center">
          <form [formGroup]="form" *ngIf="!loading">
            <div class="tw-flex tw-gap-10 tw-flex-col md:tw-flex-row">
              <div class="tw-flex tw-flex-col">
                <label class="tw-text-main-text tw-text-xs">
                  {{ 'smart_replies.config.unlimite_responses' | translate }}
                </label>
                <mh-core-lib-toggle class="tw-my-2" [value]="unlimitedFlagCtrl.value" [id]="0"
                  (onClick)="unlimiteResponsesChange()"></mh-core-lib-toggle>
              </div>
              <div class="tw-flex tw-flex-col">
                <label class="tw-text-main-text tw-text-xs">
                  {{ 'smart_replies.config.number_of_responses' | translate }}
                </label>
                <input
                  class="tw-h-[34px] tw-my-2 tw-rounded-md tw-border tw-border-border-light-gray tw-bg-fff disabled:tw-bg-main-disabled disabled:tw-text-disabled-font focus:tw-ring-transparent"
                  [ngClass]="{'tw-border-detractors': usageCtrl.errors }" type="text" formControlName="usage_limit"
                  (keypress)="numbersOnly($event)" />
                <p class="tw-text-[12px] tw-font-bold tw-text-detractors tw-text-center"
                  [ngClass]="{'tw-invisible': !usageCtrl.errors}">
                  {{ 'smart_replies.action_button.limit_error_input' | translate }}
                </p>
              </div>
            </div>
          </form>
        </mh-core-lib-loader-wrapper>
      </div>
    </mh-core-lib-generic-box>
  </mh-core-lib-actions-wrapper>


  <mh-smart-replies-conf [accept_terms]="accept_terms">
  </mh-smart-replies-conf>

  <section class="tw-flex tw-gap-2 tw-justify-center tw-mt-3">
    <mh-core-lib-button (click)="goToBack()" [isSecondary]="true"
      [text]="'commons.back' | translate"></mh-core-lib-button>

    <mh-core-lib-button (click)="saveConfig()" [isDefault]="true"
      [text]="'commons.save_changes' | translate"></mh-core-lib-button>
  </section>
</div>