import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'mh-core-lib-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreLibToggleComponent),
      multi: true,
    },
  ],
})
export class CoreLibToggleComponent implements ControlValueAccessor {
  @Output() onClick: EventEmitter<{ id: number; value: boolean; name: string }> = new EventEmitter();
  @Input() label: string;
  @Input() isDisabled = false;
  @Input() inner = false;
  @Input() id = 0;
  @Input() name = '';
  @Input() preventClick = false;
  @Input() colors = ['tw-bg-main'];
  @Input() value;
  @Input() size: 'small' | 'normal' = 'normal';   
  @Input() viewType: 'toggle' | 'checkbox' = 'toggle';
  @Input() labelPosition: 'horizontal' | 'vertical' | null = null;
  @Input() labelCheckboxClass = '';

  sizeToggle = {
    small: {
      container: 'tw-w-[38px] tw-h-[23px]',
      toggle: 'tw-w-[18.9px] tw-h-[18.9px]',
    },
    normal: {
      container: 'tw-w-[51px] tw-h-[31px]',
      toggle: 'tw-w-[27px] tw-h-[27px]',
    },
  };

  onClickEmit() {
    if (this.preventClick) {
      return;
    }
    this.value = !this.value;
    this.onChange(this.value); // Notify the parent form about the value change
    this.onTouched(); // Mark as touched
    this.onClick.emit({ id: this.id, value: this.value, name: this.name });
  }

  get color() {
    if (this.colors.length > 1) return this.value ? this.colors[this.id] : 'tw-bg-gray-50';
    return this.value ? 'tw-bg-main' : 'tw-bg-gray-50';
  }


  // ControlValueAccessor methods
  private onChange: (value: boolean) => void = () => {};
  private onTouched: () => void = () => {};

  writeValue(value: boolean): void {
    this.value = value;
  }

  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
